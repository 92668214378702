.btn-new {
  margin-left: 20px;
  cursor: pointer;
  display: inline-flex;
  justify-content: space-evenly;
  font-size: 14px;
}
.PersonModal {
  display: flex;
  justify-content: space-between;
  background: var(--button2);
}

.btn-dark {
  background-color: var(--button2) !important;
  color: white !important;
}

.btn-light {
  background-color: #eeeeff !important;
  color: #424167 !important;
}

@media screen and (max-width: 1040px) {
  .btn-new {
    margin-left: 10px;
    font-size: 13px;
  }
  .btn-section button {
    gap: 5px;
    width: 135px;
  }
  .btn-section input {
    padding: 9px 2px;
  }
  tr {
    height: 37px !important;
  }
  .personfindinput input {
    margin-left: 70px;
  }
}

@media screen and (max-width: 1296px) {
  .personfindinput input {
    margin-left: 20%;
  }
}

@media only screen and (min-width: 768px) {
  .model-box {
    max-height: 66vh;
  }
}
@media only screen and (min-width: 924px) {
  .model-box {
    max-height: 95vh;
  }
}
@media only screen and (max-width: 1080px) {
  .model-box {
    max-height: 96vh;
  }
}
@media only screen and (max-width: 1362px) {
  .btn-down {
    right: -91.5% !important;
  }
}
@media only screen and (max-width: 1104px) {
  .btn-down {
    right: -90% !important;
  }
}
@media only screen and (min-width: 1754px) {
  .model-box {
    max-height: 68vh;
  }
}
