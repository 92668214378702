/* Mixin */
.flex {
  display: -ms-box;
  display: flex;
}

/* .innerPage {
  display: inline-block;
  width: calc(100% - 80px);
  margin: 64px 0 0 80px;
  padding: 0 20px;
} */

.popup_merge{
  margin: 15px;
}
.merge_popup{
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.target_merge_person{
  margin-top: 20px;
}
.target_input{
  width: 90%;
  margin-top: 10px;
  height: 30px;
 
}
.merge_btn {
  margin-top: 20px;
  display: flex;
  justify-content:space-between;
}
.confirm_btn{
 margin-top:20px;
 display: flex;
 justify-content:space-between;

}
.confirm_popup{
margin: 20px;
}
.confirm {
  display: flex;
  justify-content: center; 
  align-items: center;     
  height: 100%;          
}

.final_popup {
  text-align: center;      
}
.personMainTabWrapper {
  position: relative;
  width: 100%;
  background-color: #fff;
  padding: 0;
  border-radius: 5px;
  margin: 20px 0 30px;
  /* modal styling here */
}
.personMainTabWrapper .tabwapper {
  padding: 0;
  border: 0px;
  background: var(--color2);
}
.personMainTabWrapper .tabwapper .tab-menu {
  width: 100%;
  padding: 0;
}
.personMainTabWrapper .tabwapper .tab-menu ul {
  list-style: none;
  border-bottom: 1px solid #ccc;
  display: -ms-box;
  display: flex;
}
.personMainTabWrapper .tabwapper .tab-menu ul li {
  max-width: 100%;
  position: relative;
}
.personMainTabWrapper .tabwapper .tab-menu ul li a {
  color: rbg(0, 0, 0, 0.3);
  text-transform: capitalize;
  font-weight: 500;
  display: inline-block;
  padding: 15px 24px;
  font-size: 0.875rem;
  text-decoration: none;
  position: relative;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
}
.personMainTabWrapper .tabwapper .tab-menu ul li a:hover {
  color: #424167;
  text-decoration: none;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
}
.personMainTabWrapper .tabwapper .tab-menu ul li a.active {
  color: #424167;
  text-decoration: none;
  padding: 15px 48px 15px 24px;
  position: relative;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
}
.personMainTabWrapper .tabwapper .tab-menu ul li .personCls {
  display: flex;
  z-index: 1;
}
.personMainTabWrapper .tabwapper .tab-menu ul li .personCls .cls {
  margin-top: 16px;
  position: relative;
  left: -30px;
  cursor: pointer;
}
.personMainTabWrapper .tabwapper .tab-menu ul li a.active::after {
  content: "";
  position: absolute;
  top: 16px;
  right: 18px;
  width: 12px;
  height: 12px;
  /* background: url('../../assets/images/icons/xclose.svg') no-repeat; */
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
}
.personMainTabWrapper .tabwapper .tab-box {
  display: none;
}
.personMainTabWrapper .tabwapper .tab-main-box {
  background: var(--color2);
  margin-top: 24px;
  padding: 0px 15px 15px;
  border-radius: 5px;
  height: 740px;
}
.personMainTabWrapper .tabwapper .tab-main-box .tabcontent {
  width: 100%;
}
.personMainTabWrapper .tabwapper .tab-main-box .interaction {
  margin-top: 10px;
  justify-content: space-between;
}
.personMainTabWrapper .tabwapper .tab-main-box .interaction .leftAction {
  padding: 0;
}
.personMainTabWrapper .tabwapper .tab-main-box .interaction .leftAlign {
  gap: 17px;
  display: flex;
  justify-content: space-between;
}
.personMainTabWrapper .tabwapper .tab-main-box .userDetails {
  margin-top: 30px;
  display: -ms-box;
  display: flex;
  justify-content: flex-start;
  gap: 30px;
}
.personMainTabWrapper .tabwapper .tab-main-box .userDetails .profilePic {
  width: 160px;
  overflow: hidden;
}
.personMainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .profilePic
  .avatar-upload {
  position: relative;
  max-width: 160px;
  width: 160px;
  height: 160px;
}
.personMainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .profilePic
  .avatar-upload
  .avatar-edit {
  content: "";
  position: absolute;
  left: 40%;
  top: 40%;
  background: url("../../assets/images/icons/upload.svg") no-repeat;
  z-index: 1;
}
.personMainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .profilePic
  .avatar-upload
  .avatar-edit
  input {
  display: none;
}
.personMainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .profilePic
  .avatar-upload
  .avatar-edit
  input
  + label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  cursor: pointer;
}
.personMainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .profilePic
  .avatar-upload
  .avatar-preview {
  width: 160px;
  height: 160px;
  position: relative;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
}

.personMainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .profilePic
  .avatar-upload
  .avatar-preview
  > div {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.personMainTabWrapper .tabwapper .tab-main-box .userDetails .infodetails {
  width: calc(100% - 200px);
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
}
.personMainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .infodetails
  .fieldWrap {
  display: inline-block;
  width: 23%;
  min-width: 299px;
}
.personMainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .infodetails
  .fieldWrap.widthdouble {
  width: 48%;
}
.personMainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .infodetails
  .fieldWrap.error {
  position: relative;
}
.personMainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .infodetails
  .fieldWrap.error
  label {
  color: red;
}
.personMainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .infodetails
  .fieldWrap.error
  input {
  border-color: red;
  color: red;
}
.personMainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .infodetails
  .fieldWrap.error
  input:placeholder {
  color: red;
}
.personMainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .infodetails
  .fieldWrap.error
  input:hover,
.personMainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .infodetails
  .fieldWrap.error
  input:focus {
  border-color: red;
}
.personMainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .infodetails
  .fieldWrap.error
  .errorIcon {
  position: absolute;
  top: 45px;
  right: 6px;
}
.personMainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .infodetails
  .fieldWrap.error
  p.errorText {
  position: absolute;
  top: 73px;
  left: 0px;
  background-color: red;
  color: white;
  padding: 10px;
  font-size: 0.875rem;
  border-radius: 5px;
}
.personMainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .infodetails
  .fieldWrap:last-child {
  padding-bottom: 0;
}
.personMainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .infodetails
  .fieldWrap
  .checkBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.personMainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .infodetails
  .fieldWrap
  .checkBox
  input[type="checkbox"] {
  height: 20px;
  width: 20px;
  margin-right: 12px;
  cursor: pointer;
}
.personMainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .infodetails
  .fieldWrap
  .checkBox
  label {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 0px;
  width: 100%;
  display: inline-block;
}
.personMainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .infodetails
  .fieldWrap
  label {
  font-size: 14px;
  line-height: 28px;
  margin-bottom: 7px;
  width: 100%;
  display: inline-block;
}
.personMainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .infodetails
  .fieldWrap
  input {
  height: 40px;
  padding: 8px;
  font-size: 0.875rem;

  width: 100%;
  border-radius: 5px;
  border: 1px solid #ccc;
}
.personMainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .infodetails
  .fieldWrap
  input:hover,
.personMainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .infodetails
  .fieldWrap
  input:focus {
  outline: none;
  border-color: #424167;
}
.personMainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .infodetails
  .fieldWrap
  input[type="checkbox"] {
  height: 30px;
  width: 30px;
}
.personMainTabWrapper .tabwapper .tab-main-box .authentication {
  width: 100%;
  padding: 20px;
  margin-top: 30px;
  border: 1px solid #ccc;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
}
.personMainTabWrapper .tabwapper .tab-main-box .authentication .fieldWrap {
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
}
.personMainTabWrapper
  .tabwapper
  .tab-main-box
  .authentication
  .fieldWrap
  .checkBox
  label {
  font-size: 0.875rem;
  /* color: rgba(0, 0, 0, 0.4); */
  color: #00000099;
}
/* 
  .personMainTabWrapper
    .tabwapper
    .tab-main-box
    .authentication
    .fieldWrap
    .checkBox input[type="checkbox"]:checked {
      background: black;
      color: white;
    }
  
  input[type="checkbox"] {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    background: white !important;;
    height: 16px;
    width: 16px;
    border: 1px solid black;
    color: white;
  }
  
  input[type="checkbox"]:after {
    content: ' ';
    position: relative;
    left: 40%;
    top: 20%;
    width: 15%;
    height: 40%;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(50deg);
    display: none;
  }
  
  input[type="checkbox"]:checked:after {
    display: block;
  } */

.personMainTabWrapper .tabwapper .tab-main-box .authentication .selectMethod {
  width: 100%;
  margin-top: 24px;
}
.personMainTabWrapper .tabwapper .tab-main-box .authentication .selectMethod p {
  font-size: 0.875rem;
  /* color: rgba(0, 0, 0, 0.4); */
  color: #00000099;
}
.personMainTabWrapper
  .tabwapper
  .tab-main-box
  .authentication
  .selectMethod
  .method {
  margin-top: 24px;
}
.personMainTabWrapper
  .tabwapper
  .tab-main-box
  .authentication
  .selectMethod
  .method
  .radioWithField {
  display: flex;
  align-items: flex-start;
  gap: 100px;
}
.personMainTabWrapper
  .tabwapper
  .tab-main-box
  .authentication
  .selectMethod
  .method
  .radioWithField
  .option {
  display: block;
  position: relative;
  min-width: 320px;
}
.personMainTabWrapper
  .tabwapper
  .tab-main-box
  .authentication
  .selectMethod
  .method
  .radioWithField
  .option
  .lable-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}
.personMainTabWrapper
  .tabwapper
  .tab-main-box
  .authentication
  .selectMethod
  .method
  .radioWithField
  .option
  .lable-info
  input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 1;
  margin: 0;
  cursor: pointer;
}
.personMainTabWrapper
  .tabwapper
  .tab-main-box
  .authentication
  .selectMethod
  .method
  .radioWithField
  .option
  .lable-info
  label {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  color: #000;
  padding-left: 40px;
  position: relative;
  line-height: 24px;
}
.personMainTabWrapper
  .tabwapper
  .tab-main-box
  .authentication
  .selectMethod
  .method
  .radioWithField
  .option
  .lable-info
  label::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  top: 0;
  border: 1px solid #8d8d8d;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
}
.personMainTabWrapper
  .tabwapper
  .tab-main-box
  .authentication
  .selectMethod
  .method
  .radioWithField
  .option
  .lable-info
  input:checked
  + label::before {
  content: "";
  background: #302e2e;
  position: absolute;
  width: 10px;
  height: 10px;
  left: 6px;
  top: 6px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
}
.personMainTabWrapper
  .tabwapper
  .tab-main-box
  .authentication
  .selectMethod
  .method
  .radioWithField
  .option
  .option-control {
  display: block;
  min-height: 40px;
  padding: 8px;
  border: 1px solid #ccc;
  font-size: 0.875rem;
  width: 100%;
  line-height: 16px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  margin-left: 40px;
  margin-top: 15px;
}
.personMainTabWrapper
  .tabwapper
  .tab-main-box
  .authentication
  .selectMethod
  .method
  .radioWithField
  .option
  .option-control:hover,
.option-control:focus {
  outline: none;
  border-color: #424167;
}
.personMainTabWrapper .btn {
  font-size: 0.875rem;
  display: inline-flex;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  cursor: pointer;
  background: #424167;
  border-radius: 5px;
  padding: 12px 10px;
  position: relative;
  outline: 0;
  border: 0;
  line-height: 1.4;
  width: auto;
}
.personMainTabWrapper .btn.largebuton {
  padding: 12px 30px;
}
.personMainTabWrapper .btn.btnlightblue {
  background: var(--button);
  color: var(--TextColor);
}
.personMainTabWrapper .btn.btnlightblue:hover {
  color: #fff;
  background: var(--btnColor);
}
.personMainTabWrapper .btn.btnlightblue:hover svg path {
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  fill: #fff;
}
.personMainTabWrapper .btn.btnblue {
  color: #fff;
  background: #424167;
}
.personMainTabWrapper .modal {
  display: none;
  position: fixed;
  z-index: 99;
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: rgba(217, 217, 217, 0.8);
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
}
.personMainTabWrapper .modal .modalWrapper {
  position: relative;
  background-color: #fff;
  margin: auto;
  width: 80%;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
}
.personMainTabWrapper .modal .modalWrapper.modelSizeMedium {
  max-width: 768px !important;
}
.personMainTabWrapper .modal .modalWrapper .modelHeader {
  background: #f5f5f4;
  padding: 12px;
  color: #000;
  line-height: 24px;
  display: -ms-box;
  display: flex;
  font-size: 0.875rem;
}
.personMainTabWrapper .modal .modalWrapper .modelHeader svg {
  margin-right: 12px;
}
.personMainTabWrapper .modal .modalWrapper .close {
  color: #000;
  font-size: 1.75rem;
  font-weight: 500;
  position: absolute;
  right: 16px;
  top: 6px;
  cursor: pointer;
}
.personMainTabWrapper .modal .modalWrapper .close:hover,
.close:focus {
  color: red;
  text-decoration: none;
}
.personMainTabWrapper .modal .modalWrapper .modelContent {
  padding: 30px;
}
.personMainTabWrapper .modal .modalWrapper .modelContent .fieldWrap {
  position: relative;
  display: inline-block;
  width: 100%;
  padding-bottom: 20px;
}
.personMainTabWrapper .modal .modalWrapper .modelContent .fieldWrap.error {
  position: relative;
  font-size: 0.875rem;
}
.personMainTabWrapper
  .modal
  .modalWrapper
  .modelContent
  .fieldWrap.error
  label {
  color: red;
}
.personMainTabWrapper
  .modal
  .modalWrapper
  .modelContent
  .fieldWrap.error
  input {
  border-color: red;
  color: red;
}
.personMainTabWrapper
  .modal
  .modalWrapper
  .modelContent
  .fieldWrap.error
  input:hover,
.personMainTabWrapper
  .modal
  .modalWrapper
  .modelContent
  .fieldWrap.error
  input:focus {
  border-color: red;
}
.personMainTabWrapper
  .modal
  .modalWrapper
  .modelContent
  .fieldWrap.error
  .errorIcon {
  position: absolute;
  top: 45px;
  right: 6px;
}
.personMainTabWrapper
  .modal
  .modalWrapper
  .modelContent
  .fieldWrap.error
  p.errorText {
  position: absolute;
  top: 73px;
  left: 0px;
  background-color: red;
  color: white;
  padding: 10px;
  font-size: 0.875rem;
  border-radius: 5px;
}
.personMainTabWrapper .modal .modalWrapper .modelContent .fieldWrap .eyebtn {
  position: absolute;
  top: 44px;
  right: 15px;
  width: 24px;
  height: 18px;
  display: block;
  background: url(../../assets/images/icons/eyecross.svg) no-repeat;
  cursor: pointer;
}
.personMainTabWrapper
  .modal
  .modalWrapper
  .modelContent
  .fieldWrap
  .eyebtn.eyeopen {
  background: url(../../assets/images/icons/eyeopen.svg) no-repeat;
}
.personMainTabWrapper .modal .modalWrapper .modelContent .fieldWrap label {
  font-size: 0.875rem;
  line-height: 28px;
  margin-bottom: 7px;
  width: 100%;
  display: inline-block;
}
.personMainTabWrapper .modal .modalWrapper .modelContent .fieldWrap input {
  font-size: 0.875rem;
  height: 40px;
  padding: 8px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ccc;
}
.personMainTabWrapper .modal .modalWrapper .modelContent .fieldWrap input:hover,
.personMainTabWrapper
  .modal
  .modalWrapper
  .modelContent
  .fieldWrap
  input:focus {
  outline: none;
  border-color: #424167;
}
.personMainTabWrapper .modal .modalWrapper .modelContent .actionButton {
  width: 100%;
  display: -ms-box;
  display: flex;
  justify-content: flex-end;
  gap: 24px;
  margin-top: 4px;
}

.Detail_Contact_Tab {
  margin-left: 0 !important;
}
.screen_num {
  padding: 0px 12px;
  gap: 8px;
  width: 45px;
  height: 20px;
  background: #fef2f2;
  border-radius: 4px;
  margin-top: -1%;
}

.screen_num p {
  color: #dc2626;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  margin-top: 4px;
  margin-bottom: 40px;
}
