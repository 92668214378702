.page-info-buttons {
  display: flex;
  justify-content: space-between;
}
.page-info-buttons .primary_btn {
  display: flex;
  align-items: center;
  gap: 5px;
  width: auto;
  background-color: var(--button2);
  color: white;
}
.page-info-buttons .secondary_btn {
  display: flex;
  align-items: center;
  gap: 5px;
  width: auto;
  background: var(--button-lightblack-lightblue);
  color: var(--TextColor);
}
.page-section-fileds {
  border: 1px solid rgb(228, 224, 224);
  border-radius: 6px;
  height: auto;
  padding: 10px;
  height: auto;
  display: flex;
  gap: 30px;
}
.first-input-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
}
.first-input-section .input-flex-div {
  display: flex;
  gap: 10px;
  align-items: center;
}
.first-input-section .go-to-inputs-field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: inherit;
  height: auto;
}
.input-section-border {
  width: auto;
  height: 112px;
  border: 1px solid rgb(228, 224, 224);
  border-radius: 6px;
  padding: 10px;
}

.page-section-fileds .profilePic {
  width: 160px;
  /* overflow: hidden; */
}
.page-section-fileds .profilePic .avatar-upload {
  position: relative;
  max-width: 160px;
  width: 160px;
  height: 160px;
}
.page-section-fileds .profilePic .avatar-upload .avatar-edit {
  content: "";
  position: absolute;
  left: 40%;
  top: 40%;
  background: url("../../../assets/images/icons/upload.svg") no-repeat;
  z-index: 1;
}
.page-section-fileds .profilePic .avatar-upload .avatar-edit input {
  display: none;
}
.page-section-fileds .profilePic .avatar-upload .avatar-edit input + label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  cursor: pointer;
}
.page-section-fileds .profilePic .avatar-upload .avatar-preview {
  width: 160px;
  height: 160px;
  position: relative;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
}
.page-section-fileds .profilePic .avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.details-address .fee-payment-content-info-input {
  width: 372px;
}
.details-address .card-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid rgb(228, 224, 224);
  margin: 0;
}
.details-name .fee-schedule-fileds-content {
  display: flex;
  align-items: flex-end;
  gap: 24px;
  justify-content: space-between;
  width: 529px;
}
.details-name .edit-content-space > div {
  width: 250px !important;
  margin-top: 0px !important;
}
.details-address .card-container-header {
  display: flex;
  align-items: center;
  gap: 10px;
}
.input-custom-width {
  width: 170px !important;
}
.contacts-entity-list {
  display: flex;
  gap: 20px;
  padding: 10px;
  flex-wrap: wrap;
}
.find-contact-popup {
  height: 518px;
  border: 3px solid var(--button2);
}
