.dark_mode {
  margin-top: -20px;
}
.dark_mode_label {
  width: 65px;
  height: 30px;
  position: relative;
  display: block;
  background: #333;
  border-radius: 200px;
  cursor: pointer;
  transition: 0.3s;
}
.dark_mode_label:after {
  content: "";
  width: 25px;
  height: 25px;
  position: absolute;
  top: 3px;
  left: 3px;
  background: #fff;
  border-radius: 180px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
.dark_mode_input {
  width: 0;
  height: 0;
  visibility: hidden;
}
.dark_mode_input:checked + .dark_mode_label {
  background: #fff;
}
.dark_mode_input:checked + .dark_mode_label:after {
  left: 62px;
  transform: translateX(-100%);
  background: #000000;
}
.dark_mode_label:active:after {
  width: 30px;
}
.dark_mode_label svg {
  position: absolute;
  width: 20px;
  top: 5px;
  z-index: 100;
}
.dark_mode_label svg.sun {
  left: 5px;
  fill: #333;
  transition: 0.3s;
}
.dark_mode_label svg.moon {
  left: 40px;
  fill: #fff;
  transition: 0.3s;
}
.dark_mode_input:checked + .dark_mode_label svg.sun {
  fill: #000000;
}
.dark_mode_input:checked + .dark_mode_label svg.moon {
  fill: #fff;
}
