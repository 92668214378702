.tabs_admin {
  background-color: var(--color2);
  color: rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid #e6e6e6;
  overflow-x: auto;
  white-space: nowrap;
}

.tabs_admin .tabs_admin_tabs {
  display: flex;
}

.tabs_admin .tabs_admin_tabs .tabs_admin_tab {
  cursor: pointer;
  height: 40px;
  margin-left: 10px;
  display: inline-flex;
  gap: 10px;
  align-items: center;
  white-space: nowrap;
}

.tabs_admin .tabs_admin_tabs .tabs_admin_tab_name {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
}
