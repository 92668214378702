#modal {
  padding: 0%;
  border-radius: 6px;
}
.RecivePaymentModel {
  border: 3px solid var(--button2);
  height: 647px;
}
.RecivePaymentModel_ {
  border: 3px solid var(--button2);
}
.paymentcc {
  width: 96%;
  margin-left: 2%;
}

.RecivePaymentModel_input_block_ {
  margin-top: 3%;
  font-weight: 400;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  width: 48%;
}
.RecivePaymentModel_input_block_ p {
  color: var(--TextColor);
  font-weight: 530;
  font-size: 12.7px;
}

.paymentcc_cardentry {
  height: 96px;
  width: 96%;
  margin-left: 2%;
  margin-top: 2%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.RecivePaymentModel_header {
  height: 48px;
  background: var(--button2);
  display: flex;
  justify-content: space-between;
}
.RecivePaymentModel_header_name {
  display: flex;
  margin-left: 1.5%;
  width: 16%;
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.RecivePaymentModel_header_name p {
  color: white;
}

.RecivePaymentModel_block1_sub_input_block {
  font-weight: 400;
  font-size: 12px;
  display: flex;
  flex-direction: column;
}
.paymentccbuttonsd {
  display: flex;
  justify-content: end;
}

.paymentccbuttonsd button {
  background-color: #424167;
  color: white;
  margin: 1.5%;
}

.RecivePaymentModel_block1_sub_input_block input {
  width: 100%;
  height: 36px;
  border-radius: 3px;
  margin-top: 2%;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
  font-size: 13.5px;
  border: 1px solid #ccc;
}

.RecivePaymentModel_block1_sub_input_block input:hover,
.RecivePaymentModel_block1_sub_input_block input:focus {
  outline: none;
  border-color: #424167;
}
.RecivePaymentModel_block1_sub_textarea {
  margin-top: 3%;
  width: 100%;
  height: 130px;
}

.RecivePaymentModel_block1_sub_textarea:hover,
.RecivePaymentModel_block1_sub_textarea:focus {
  outline: none;
  border-color: #424167;
}

.RecivePaymentModel_block1 {
  display: flex;
  margin: 1.5%;
  margin-top: 1%;
  gap: 1%;
}

.RecivePaymentModel_block1 .RecivePaymentModel_block1_sub {
  display: flex;
  align-items: center;
  gap: 1%;
  margin: 1.1%;
}

.RecivePaymentModel_block1 .RecivePaymentModel_block1_sub.block_margin {
  display: flex;
  align-items: center;
  gap: 1%;
  margin: 1.1% 0;
}

.RecivePaymentModel_block1 .RecivePaymentModel_block1_sub p {
  font-style: normal;
  font-weight: 530;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: var(--TextColor);
}

span {
  color: red;
}

#richEdit span{
color: #000000;
}

textarea {
  border: 1px solid #d2d2d2;
  border-radius: 6px;
  height: 130px;
}
.dropdownn {
  border-radius: 3px;
  height: 130px;
  position: absolute;
  width: 100%;
  background-color: white;
  border: 1px solid #e6e6e6;
  top: calc(100% + 2px);
  z-index: 10;
}

.dropdownn p {
  cursor: pointer;
  margin: 1%;
}
.RecivePaymentModel_block2 {
  display: flex;
  width: 21%;
  justify-content: space-between;
  margin-top: -7%;
  margin-left: 1.5%;
}
.RecivePaymentModel_block3_p p {
  font-size: 14px;
}

.RecivePaymentModel_block2 p {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-top: 9px;
}

button {
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;
  width: 157px;
  height: 40px;
  background: #eeeeff;
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
  border: none;
}

p {
  color: var(--TextColor);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
}

.RecivePaymentModel_block3 div {
  display: flex;
}

.RecivePaymentModel_block3 {
  margin-top: 1.5%;
}

/* AddQuickTask */
.AddQuickTask {
  margin: 6%;
  width: 90%;
}

.AddQuickTask h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  align-items: center;
  color: #000000;
}

.AddQuickTask p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 5%;
  cursor: pointer;
}

.AllocationAdjustment_block1 {
  display: flex;
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  margin: 1%;
}
.AllocationAdjustment_block1 p {
  color: black;
  font-weight: 500;
}
.AllocationAdjustment_block1 .AllocationAdjustment_block1_sub {
  width: 35%;
  margin: 1.5%;
}
.AllocationAdjustment_block2 {
  margin: 1%;
}
.email-container{
  width: 100%;
  /* height: 822px; */
  /* border: 3px solid var(--button2); */
}
.email-container .email-item {
  width: 100%;
  display: flex;
  gap: 32px;
  padding: 20px;
}
.email-item .left-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 300px;
  gap: 15px;
}
.email-item .right-item {
  width: 800px;
  padding: 16px;
  border-radius: 16px;
  border: 1px solid #0000001a;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.email-item .input-container {
  display: flex;
  width: 300px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}
.email-item .custom-width-dropdown > div {
  margin-top: 0px !important;
  width: 300px;
}
.email-item .input-container label {
  font-weight: 530;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: var(--TextColor);
}
.email-item .input-container .input-type {
  width: 300px;
  height: 40px;
  padding: 8px;
  border: 1px solid var(--greyColor);
  border-radius: 4px;
}
.email-item .input-container .textarea-type {
  width: 300px;
  height: 125px;
  padding: 8px;
  border-radius: 4px;
}
.email-item .flex {
  flex-direction: row;
  gap: 30px;
}
.email-item .checkbox-flex {
  display: flex;
  align-items: center;
  gap: 12px;
}
.btn-left {
  background-color: #424167;
  width: 96px;
  height: 40px;
  color: #fff;
  float: right;
  margin-right: 50px;
  cursor: pointer;
}
.popup-border {
  width: 100%;
  height: 647px;
  border: 3px solid var(--button2);
}
.add_quick_task {
  padding: 20px;
  width: 100%;
}
.add_quick_task .add_quick_task_para {
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: var(--TextColor);
}
.add_quick_task .add_quick_task_list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  margin-top: 20px;
}
.add_quick_task .add_quick_task_list_data {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: var(--TextColor);
  cursor: pointer;
}
.quick_add_task_container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
}
.tabs_container {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  border-bottom: 1px solid grey;
}

.tabs_container_tab {
  font-size: 14px;
  font-family: Readex Pro;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  height: 40px;
  padding: 6px 16px;
  cursor: pointer;
}
.tabs_container_tab_active {
  border-bottom: 4px solid var(--button2);
  color: var(--button2);
}
.allocation_adjustment_para {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #333;
}
.remove-input-border-editor .dx-texteditor-input {
  border: none !important;
}
