.editfilterPopup .showsetfilter {
  margin-left: 5%;
  width: 48%;
  display: flex;
  justify-content: space-between;
  margin-top: 1%;
}

.hoverable_icon {
  cursor: pointer;
  margin-left: 1%;
}

.editfilterPopup {
  border: 2px solid var(--button2);
  height: 398px;
}

.editfilterPopup .showsetfilter input {
  width: 55%;
  height: 26px;
}
.editfilterPopup .showsetfilter label {
  margin-top: 5px;
}
.editfilterPopup .showsetfilter button {
  width: 12%;
  height: 26px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
    0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
.allshowfilter {
  margin-left: 6%;
  margin-top: 1%;
}

.editfilterPopup .end_button {
  display: flex;
  justify-content: end;
  margin-top: 9%;
  margin-right: 5%;
  margin-bottom: 4%;
}
.editfilterPopup .end_button button {
  cursor: pointer;
}

.Administration {
  margin-top: 1.1%;
  background-color: #ffffff;
  border-radius: 5px;
  overflow: hidden;
}
.Administration_block_2_sub {
  display: flex;
  margin: 1%;
}

.admistrater_edit_popup_header {
  height: 48px;
  background: var(--button2);
  display: flex;
  justify-content: space-between;
}
.admistrater_edit_popup_header_sub {
  display: flex;
  justify-content: space-around;
  width: 17%;
  margin-left: 2%;
}
.admistrater_edit_popup_header_sub p {
  color: black;
  font-weight: 500;
}
