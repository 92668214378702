.payment-credits-tab {
  display: flex;
  height: auto;
  gap: 60px;
  width: 99%;
}
.payment-credits-tab-nav {
  display: flex;
  flex-direction: column;
  width: 137px;
  height: auto;
  gap: 20px;
}
.payment-credits-tab-data {
  width: calc(100% - 170px);
  height: auto;
}

.payment-credits-tab-nav-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 8px;
  gap: 9px;
  width: 187px;
  height: 40px;
  background-color: var(--btn-grey-black);
  color: var(--white-black);
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  border: none;
}

.payment-credits-tab-active {
  background: var(--btnColor);
  color: #ffffff;
}
