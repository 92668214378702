.gotodockerheader {
  border: 1px solid rgb(228, 224, 224);
  width: 98%;
  margin-left: 1%;
  margin-top: 2%;
  padding: 10px;
  border-radius: 6px;
}
.gotodockerheaderedit {
  display: flex;
  justify-content: space-between;
}
.gotodockerheadeoffender {
  display: flex;
  margin-top: 20px;
}
.gotodockerheadeoffender div {
  display: flex;
}
.gotodockerheadeoffender div p {
  color: var(--TextColor);
  font-weight: bold;
}
.gotodockerheadeheader {
  display: flex;
  justify-content: space-between;
}
.gotodockerheadeheader p {
  color: var(--TextColor);
  font-weight: bold;
}
.generatePaymentPlandiv_gotodoctet {
  width: 300px;
  margin-top: 15px;
}
.gotodockerheadeblock3 {
  margin-top: 25px;
}
.gotodockerheadeblock3 div {
  display: flex;
  margin-top: 13px;
}
.gotodockerheadeblock3 div p {
  width: 170px;
}
