.community_service,
.planned_services,
.completed_service,
.jail_time,
.tolling,
.warrents,
.drug_test {
  display: flex;
  flex-direction: column;
  gap: 13px;
}
.program_voidpopup {
  width: 100%;
  height: 448px;
  border: 3px solid var(--button2);
  background: var(--popup-background-white-black);
}
.program_editpopup {
  width: 100%;
  height: 858px;
  border: 3px solid var(--button2);
  background: var(--popup-background-white-black);
}
.program_void_content,
.program_edit_content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px;
  padding-top: 30px;
}
.program_void_content_info {
  display: flex;
  gap: 20px;
}
.program_void_content_info .sub_para {
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  color: var(--TextColor);
}
.program_void_content_info .highlight_para {
  padding: 8px 16px;
  display: inline-block;
  height: 32px;
  background: #fef2f2;
  border-radius: 100px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #dc2626;
}
.program_void_content .info_para {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: var(--light-grey-white-color);
}
.program_void_content_textarea label {
  font-weight: 530;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: var(--TextColor);
  margin-bottom: 5px;
}
.program_void_content_textarea textarea {
  padding: 8px;
  width: 100%;
  height: 125px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 5px;
}
.popup_buttons {
  display: flex;
  justify-content: end;
  gap: 15px;
}
.btn {
  background: var(--button-lightblack-lightblue);
  color: var(--icons2);
  display: flex;
  align-items: center;
  width: auto;
  height: 40px;
  cursor: pointer;
  font-size: 14px;
  font-family: Readex Pro;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
}
.primary_btn {
  background-color: var(--button2);
  color: #fff;
  display: flex;
  align-items: center;
  width: auto;
  height: 40px;
  cursor: pointer;
  font-size: 14px;
  font-family: Readex Pro;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
}
.primary_btn:disabled {
  background-color: lightgray;
}
.edit_container_label {
  font-weight: 530;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: var(--TextColor);
  width: 200px;
  text-align: end;
}

.inner_width {
  font-weight: 530;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: var(--TextColor);
  text-align: end;
}
.edit_container_input {
  width: 340px;
  height: 40px;
  padding: 8px;
  border: 1px solid var(--greyColor);
  border-radius: 4px;
}
.program_edit_content {
  margin-bottom: 20px;
}
.program_edit_content .dx-texteditor-input {
  width: 300px;
  height: 40px;
}
.add_jail_service {
  width: 100%;
  height: 418px;
  border: 3px solid var(--button2);
  background-color: var(--popup-background-white-black);
}
.add_jail_services_log {
  width: 100%;
  height: 478px;
  border: 3px solid var(--button2);
  background-color: var(--popup-background-white-black);
}
.add_warrents_popup {
  height: 678px;
  border: 3px solid var(--button2);
  background-color: var(--popup-background-white-black);
}
.warrents-notice-container {
  height: 150px;
  border: 3px solid var(--button2);
  background-color: var(--popup-background-white-black);
  padding: 20px;
}
.warrents-notice-content {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 30px;
}
.warrents-notice-content .warrents-warning-img {
  height: 50px;
}
.warrents-notice-content p {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--TextColor);
  letter-spacing: 0.1px;
}

.set-height {
  height: 70px;
}