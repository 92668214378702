.signPage {
  background-color: white;
  .signInner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 380px;
    max-width: 90%;
    margin: 0 auto;
    box-shadow: 5px 5px 50px 1px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 30px;

    p {
      &.msg {
        font-size: 14px;
        line-height: 20px;
        color: #475569;
        margin-bottom: 15px;
      }
    }

    h1 {
      font-size: 22px;
      line-height: 28px;
      margin-bottom: 20px;
      text-align: center;
      font-weight: 400;
    }

    .logo {
      text-align: center;
      margin-bottom: 20px;
      display: inline-block;
      width: 100%;
      img{
        height: 50px;
      }

      a {
        &:hover {
          text-decoration: none;
        }
      }
    }

    form {
      .codeResent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;

        p {
          font-size: 14px;
          line-height: 20px;
          color: #424167;
        }

        em {
          font-style: normal;
          font-size: 14px;
          line-height: 20px;
          color: rgba(0, 0, 0, 0.4);
        }
      }

      .fieldWrap {
        display: inline-block;
        width: 100%;
        padding-bottom: 20px;
        position: relative;

        &.error {
          position: relative;

          label {
            color: red;
          }

          input {
            border-color: red;
            color: red;

            &:hover,
            &:focus {
              border-color: red;
            }
          }

          .errorIcon {
            position: absolute;
            top: 4px;
            margin-left: 10px;
            cursor: pointer;
          }

          p.errorText {
            position: absolute;
            top: 73px;
            left: 0px;
            background-color: red;
            color: white;
            padding: 10px;
            font-size: 14px;
            border-radius: 5px;
          }
        }

        &:last-child {
          padding-bottom: 0;
        }

        .checkBox {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          input[type="checkbox"] {
            height: 20px;
            width: 20px;
            margin-right: 12px;
            cursor: pointer;
          }

          label {
            line-height: 28px;
            margin-bottom: 0px;
            width: 100%;
            display: inline-block;
            font-weight: 400;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.6);
          }
        }

        label {
          line-height: 28px;
          margin-bottom: 7px;
          width: 100%;
          font-weight: 400;
          font-size: 12px;
          display: inline-block;
          color: black;
        }

        input {
          height: 40px;
          padding: 8px;
          font-size: 14px;
          line-height: 20px;
          width: 100%;
          border-radius: 5px;
          border: 1px solid #ccc;

          &:hover,
          &:focus {
            outline: none;
            border-color: #424167;
          }
        }

        input[type="checkbox"] {
          height: 30px;
          width: 30px;
        }

        .btn {
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #424167;
          border-radius: 4px;
          height: 40px;
          border: none;
          width: 100%;
          font-size: 14px;
          line-height: 20px;
          color: white;
          cursor: pointer;
          margin-top: 5px;
        }

        .linkWrap {
          width: 100%;
          margin: 0 auto;
          text-align: center;
          margin-top: 20px;

          .link {
            p {
              color: #424167;
              font-size: 14px;
              text-align: center;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
