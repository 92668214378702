.flex-frame-content {
  display: flex;
  justify-content: space-between;
}
.border-frame {
  display: flex;
  width: 554px;
  height: 560px;
  padding: 190px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px solid rgb(228, 224, 224);
}
.frame-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}
.frame-content-header {
  display: flex;
  align-items: flex-start;
  gap: 32px;
}
.frame-content-data {
  display: flex;
  align-items: flex-start;
  gap: 32px;
}
.frame-content-side-data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}
.frame-content-header-para {
  color: var(--icons2);
  font-family: Readex Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1px;
}
.frame-content-side-data-para {
  color: var(--icons2);
  font-family: Readex Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
}
.add-drug-test-schedule {
  height: 349px;
  border: 3px solid var(--button2);
  background-color: var(--popup-background-white-black);
}
.add-drug-test-schedule-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px;
  padding-top: 30px;
}
.calendar-popup {
  height: 648px;
  border: 3px solid var(--button2);
  background-color: var(--popup-background-white-black);
}
.calendar-content,
.void-test-date-popup-content {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.calendar-content .dropdown-space > div {
  margin-top: 0px !important;
}
.calendar-content .dx-toolbar .dx-toolbar-after {
  margin-left: 254px;
}
.void-test-date-popup {
  height: 378px;
  border: 3px solid var(--button2);
  background-color: var(--popup-background-white-black);
}
