.attachment_sub_tabs {
  display: flex;
  height: 40px;
  border-bottom: 1px solid #e0dddd;
  width: 98%;
  margin-left: 1%;
}
.attachment_sub_tabsAll_Attachments {
  width: 174px;

  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
}
.attachment_sub_tabsAll_Attachments p {
  color: var(--TextColor);
  font-weight: 600;
}
.attachment_sub_tabs_photo {
  width: 88px;

  align-items: center;
  display: flex;
  justify-content: space-around;
  cursor: pointer;
}
.attachment_sub_tabs_photo p {
  color: var(--TextColor);
  font-weight: 600;
}
.AllAttachmentFiles_component_button button {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 1%;
  margin-bottom: 1%;
  margin-left: 1%;
  background: var(--button-lightblack-lightblue);
}
.AllAttachmentFiles_component_button button p {
  color: var(--TextColor);
  font-weight: 600;
  border-radius: 4px;
}
.AllAttachmentFiles_component {
  width: 98%;
  margin-left: 1%;
  height: 680px;
}
.Attechment_Photos_subButton1 {
  width: 48px;
  height: 40px;
  border-radius: 4px;
  justify-content: center;
  display: flex;
  align-items: center;
}
.Attechment_Photos {
  width: 98%;
  margin-left: 1%;
}

.Attechment_Photos_allImage .imagee {
  width: 182px;
  height: 182px;
  margin-right: 10px;
  margin-top: 10px;
}
.Attechment_Photos_allImage .imagee img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.Attechment_Photos_galery_all_images {
  width: 40%;
  height: 240px;
  border: 1px solid rgb(206, 205, 205);
  overflow-y: auto;
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
}
.Attechment_Photos_galery_all_images .imagee {
  width: 60px;
  height: 60px;
  border-radius: 6px;
  margin: 0.6%;
  object-fit: cover;
}
.Attechment_Photos_perticularImage {
  width: 60%;
}
.Attechment_Photos_perticularImage .imagee {
  object-fit: cover;
  margin: 1%;
}
.inputFieleHide {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  z-index: 111;
}
.AttachFiles_Popup_fileNameDisplay {
  width: 100%;
  height: 170px;
  border: 1px solid rgb(201, 200, 200);
  border-radius: 6px;
  margin-top: 2%;
}
.AttachFiles_Popup_dropdown {
  margin-top: 3.5%;
}
.AttachFiles_Popup_dropdown p {
  color: var(--TextColor);
  font-weight: 600;
}
.AttachFiles_Popup_discription p {
  color: var(--TextColor);
  font-weight: 600;
}
.AttachFiles_Popup_discription {
  margin-top: 3.5%;
}
.AttachFiles_Popup_fileNameDisplay_list {
  display: flex;
  justify-content: space-between;
  margin: 2%;
}
.AllAttachmentFiles_component_table {
  margin-top: 2%;
}
.AttachFiles_Popup_fileNameDisplay_list p {
  color: black;
  font-weight: 600;
  font-size: 14px;
}
.Attechment_Photos_perticularImage .imagee {
  width: 222px;
  height: 222px;
  margin-left: 35%;
}
.AttachFiles_Popup {
  width: 94%;
  margin-left: 3%;
}
.AttachFiles_Popup_button {
  display: flex;
  justify-content: space-between;
  margin-top: 2%;
}

.AttachFiles_Popup_button button {
  display: flex;
  justify-content: space-around;
  cursor: pointer;
  background: var(--button-lightblack-lightblue);
}
