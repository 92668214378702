.reportEditor {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.reportEditor_sub {
  display: flex;
  flex-direction: row;
  gap: 80px;
  height: 40px;
  align-items: center;
  padding-left: 10px;
}

.reportEditor_label {
  font-weight: 530;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: var(--TextColor);
  width: 120px;
}

.reportTable {
  padding: 2%;
}

.add_report_button {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  width: 140px;
  height: 40px;
  background: var(--button2);
  border-radius: 4px;
  color: #fff;
}
