.go-to-docket-popup-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 20px;
}
.go-to-docket-popup-fields {
  display: flex;
  align-items: flex-end;
  gap: 12px;
  align-self: stretch;
}
.go-to-inputs-field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  height: 100px;
}
.input-margin {
  margin-top: 26px;
}
.go-to-mark-input {
  color: #dc2626;
  font-size: large;
}
.go-to-error-field {
  color: #dc2626;
  text-transform: capitalize;
}
.go-to-label-filed {
  font-weight: 530;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: var(--TextColor);
}
.go-to-label-filed-light {
  font-weight: 530;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: var(--greyColor);
}
.text-underline {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-decoration-line: underline;
}
.go-to-input-filed {
  width: 340px;
  height: 40px;
  padding: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.go-to-input-filed-custom {
  width: 71px;
  height: 40px;
  padding: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.create-concurrent-docket-container {
  border: 3px solid var(--button2);
  background-color: var(--popup-background-white-black);
  height: 238px;
}
.go-to-input-find-filed {
  width: 150px;
}
.go-to-button-field {
  display: flex;
  gap: 20px;
}
.btn-width-auto {
  width: auto;
}
.go-to-docket-find {
  height: 606px;
  border: 3px solid var(--button2);
  background-color: var(--popup-background-white-black);
}
