.particcipentPage {
  margin-top: 1.1%;
  background-color: var(--color2);
  border-radius: 5px;
  overflow: hidden;
}

.homeMoreItem {
  background-color: var(--color);
  border: 1px solid var(--color);
  width: 100%;
  position: relative;
  color: var(--TextColor);
  svg {
    path {
      fill: var(--icons);
    }
  }
}

header {
  height: 64px;
  background-color: var(--color2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  position: fixed;
  width: 100%;
  z-index: 999;

  .headerLeft {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    .menuBar {
      cursor: pointer;
      height: 20px;
      width: 20px;
      svg {
        path {
          fill: var(--icons);
        }
      }

      .menuIcon {
        display: block;
      }

      .crossIcon {
        display: none;
      }

      &.open {
        .menuIcon {
          display: none;
        }

        .crossIcon {
          display: block;
        }
      }
    }

    .logo {
      display: block;
      cursor: pointer;
      img {
        max-width: 150px;
      }
    }

    .navBar {
      width: auto;
      @media only screen and (max-width: 1320px) {
        display: none;
      }
      ul {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        list-style: none;
        gap: 10px;
        li {
          a {
            font-size: 12px;
            line-height: 20px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            img,
            svg {
              margin-right: 10px;
              path {
                fill: var(--icons);
              }
            }

            &:hover,
            &.active {
              color: #424167;

              svg {
                path {
                  fill: #424167;
                  fill-opacity: 1;
                }
              }
            }
          }
        }
      }
    }
    .nav_links_mobile {
      position: absolute;
      display: block;
      list-style: none;
      top: 67px;
      width: 80%;
      height: auto;
      border-radius: 20px;
      padding: 20px;
      background-color: var(--color2);
      box-shadow: rgba(50, 50, 93, 0.23) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
      transition: all 0.5s ease-in-out;
      transform: translate(9%, 0px);
      ul {
        display: flex;
        list-style: none;
        flex-direction: column;
        gap: 23px;
        align-items: flex-start;
        li a {
          font-size: 12px;
          line-height: 20px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
        }
      }
    }
  }

  .profileUser {
    position: relative;
    display: flex;
    align-items: center;
    gap: 23px;

    .profileLink {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .profile {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        min-width: 200px;

        .detail {
          h3 {
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
            color: var(--TextColor);
          }

          em {
            font-size: 12px;
            line-height: 16px;
            font-style: normal;
          }
          .navbar_location_hover,
          .navbar_location_second_hover {
            font-size: 13px;
            font-weight: 500;
            color: var(--TextColor);
          }
          .navbar_location_hover:hover::before {
            position: absolute;
            top: 45px;
            left: 10px;
            content: attr(id);
            background-color: var(--color);
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
              0px 1px 3px 1px rgba(0, 0, 0, 0.15);
            padding: 4px;
            border-radius: 4px;
            z-index: 1000;
            cursor: pointer;
          }
          .navbar_location_second_hover:hover::before {
            position: absolute;
            top: 64px;
            left: 45px;
            content: attr(id);
            background-color: var(--color);
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
              0px 1px 3px 1px rgba(0, 0, 0, 0.15);
            padding: 4px;
            border-radius: 4px;
            z-index: 1000;
            cursor: pointer;
          }
        }
      }

      .dropdownLink {
        width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 24px;
        background: #f4f4f5;
        border-radius: 10px;
        cursor: pointer;
      }
    }

    .dropdownUser {
      position: absolute;
      top: calc(100% + 20px);
      right: 6px;
      width: 180px;
      background-color: var(--color2);
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
        0px 2px 6px 2px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      padding: 5px 0;
      display: none;

      &.open {
        display: block;
      }

      ul {
        li {
          a {
            display: flex;
            justify-content: flex-start;
            gap: 10px;
            align-items: center;
            padding: 15px;
          }
        }
      }
      .dark-theme-btn-lightBlue-black {
        background: var(--button);
        color: var(--icons);
      }
    }
  }
  .toggle_container {
    display: none;
    @media only screen and (max-width: 1320px) {
      display: block;
    }
  }
}

.add_navBar_hamburger {
  height: 40px;
  width: 40px;
  text-align: center;
  border-radius: 40px;
  background-color: var(--color);
  position: relative;
  cursor: pointer;
  svg {
    margin-top: 12px;
    path {
      fill: var(--icons);
    }
  }

  .add_navBar_hamburger_dropdown {
    position: absolute;
    top: 60px;
    left: -60px;
    width: 217px;

    ul {
      height: auto;
      background-color: var(--color2);
      display: flex;
      flex-direction: column;
      li {
        display: flex;
        margin-top: 8%;
        svg {
          margin-left: 10px;
          margin-top: 0px;
          path {
            fill: var(--icons);
          }
        }
        input {
          margin-left: 10px;
        }
        p {
          margin-left: 10px;
          color: var(--TextColor) !important;
        }
      }
      border: 1px solid var(--color2);
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
        0px 2px 6px 2px rgba(0, 0, 0, 0.5);
      border-radius: 8px;
    }
  }
}

.leftSideMenu {
  // position: fixed;
  width: 80px;
  background-color: var(--color2);
  // height: calc(100vh - 64px);
  height: 100%;
  top: 64px;
  overflow: visible;
  transition: width 100ms;
  z-index: 2;

  ul {
    list-style: none;
    .home_dropdown {
      ul {
        li {
          height: 50px;
          width: 91%;
          border-radius: 30px;
          margin-top: 2px;
          margin-left: 4%;
          border: 1px solid rgb(151, 149, 149);

          p {
            color: black;
            text-align: center;
            margin-top: 4.5%;
            width: 100%;
            margin-left: -7px;
          }
        }
      }
    }

    li {
      width: 100%;
      display: inline-block;

      a {
        font-size: 14px;
        position: relative;
        width: 100%;
        padding: 5px 14px;
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        color: rgba(0, 0, 0, 0.6);
        padding-right: 0;

        .leftIcon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          svg {
            path {
              fill: var(--icons);
            }
          }
        }
        .lefticonhover:hover::before {
          content: attr(id);
          position: absolute;
          top: 18px;
          left: 70%;
          background-color: var(--color);
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
            0px 1px 3px 1px rgba(0, 0, 0, 0.15);
          padding: 4px;
          border-radius: 4px;
          z-index: 1000;
        }
        .custom-icon-Hover:hover::before {
          content: attr(id);
          position: absolute;
          top: 18px;
          left: 70%;
          background-color: var(--color);
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
            0px 1px 3px 1px rgba(0, 0, 0, 0.15);
          padding: 4px;
          border-radius: 4px;
          z-index: 1000;
          width: 100px;
        }

        em {
          font-style: normal;
          display: none;
        }

        .rightArrow {
          position: absolute;
          right: 25px;
          top: 20px;
          display: none;
        }

        &.open {
          .rightArrow {
            img {
              transform: rotate(180deg);
            }
          }

          & + ul {
            display: block;
          }
        }

        &:hover {
          color: #424167;

          .leftIcon {
            svg {
              path {
                fill: #424167;
                fill-opacity: 1;
              }
            }
          }
        }
      }

      &.active {
        a {
          .leftIcon {
            background-color: var(--btnColor);

            svg {
              path {
                fill: #fff;
                fill-opacity: 1;
              }
            }
          }
        }
      }

      ul {
        display: none;
        padding: 0 10px;

        li {
          a {
            font-size: 14px;
            line-height: 20px;
            height: 56px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-radius: 45px;
            padding-left: 55px;
            color: rgba(0, 0, 0, 0.6);
          }

          &.active {
            a {
              background-color: #424167;
              color: #fff;
            }
          }
        }
      }
    }
  }

  &.open {
    width: 256px;
    transition: width 100ms;

    ul {
      li {
        &.active {
          a {
            .leftIcon {
              // background-color: #fff;

              svg {
                path {
                  fill: black;
                  fill-opacity: 0.6;
                }
              }
            }
          }
        }

        .rightArrow {
          display: block;
        }

        em {
          display: block;
        }
      }
    }
  }
}
.remove-background-circle {
  background: none !important;
}
.buttons_hamburger_dropdown {
  position: absolute;
  top: 60px;
  width: 217px;
  left: -80px;
  ul {
    // height: 130px;
    background-color: var(--color2);
    display: flex;
    flex-direction: column;
    li {
      display: flex;
      margin-top: 8%;
      width: 150px;
    }
    border: 1px solid var(--color2);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
      0px 2px 6px 2px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
  }
}
.home-hover-container {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1000;
  position: fixed;
  .home-hover-link {
    position: absolute;
    top: 93px;
    width: 182px;
    left: 82px;
    border-radius: 8px;
    background: var(--color2);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
      0px 2px 6px 2px rgba(0, 0, 0, 0.15);
    padding: 0 10px;
    text-align: center;
    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 8px;
      gap: 8px;
      li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        width: 150px;
        padding-left: 20px;
        height: 40px;
        border-radius: 4px;
        color: var(--TextColor);
        &:hover {
          background: var(--btnColor);
          color: #fff;
          svg {
            path {
              fill: #ffffff;
            }
          }
        }
      }
    }
  }
}
.admin_dropdown_data {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 8px;
  width: 172px;
  height: 40px;
  background: var(--btn-blue-dark);
  color: #fff;
  border-radius: 4px;
}

.dropdown_admin {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 16px;
  width: 190px;
  height: 40px;
  .heading {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--TextColor);
    cursor: pointer;
  }
  li {
    margin-left: 20px;
  }
}

.dropdown_admin_link {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;
  gap: 8px;
  width: 172px;
  height: 36px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--TextColor);
  border-radius: 4px;
  &:hover {
    background: var(--btn-blue-dark);
    color: #fff;
  }
}

.longtext {
  height: 55px;
}

.hoooo123 {
  color: #fff !important;
  background: var(--btn-blue-dark);
}
.hoooo123:hover {
  background-color: white;
}
