.add_drug_test_container,
.do_Not_CreateObligation_content {
  height: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 20px;
  border: 3px solid var(--button2);
  background-color: var(--popup-background-white-black);
}
.add_drug_flex {
  display: flex;
  gap: 10px;
  align-items: center;
}
.add_drug_userinfo_para {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: var(--TextColor);
}
.add_drug_userinfo_sub_para {
  font-weight: 500;
  color: var(--TextColor);
}
.add_drug_content_para {
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: var(--TextColor);
}
.random_tesing_container {
  height: 450px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  border: 3px solid var(--button2);
  background-color: var(--popup-background-white-black);
}
