.write_off_amount {
  border: 3px solid rgb(67, 66, 102);
  height: 570px;
}
.write_off_multiple_obligation {
  border: 3px solid rgb(67, 66, 102);
  height: 558px;
}
.write_off_info {
  display: flex;
  align-items: center;
  gap: 25px;
}
