.owl-nav {
  opacity: 0;
  .owl-next {
    &:after {
      content: "";
      height: 20px;
      width: 20px;
      display: inline-block;
      background: url(./assets/images/icons/arrow-right.svg) no-repeat center
        center;
      position: absolute;
      right: -15px !important;
      top: 11px !important;
    }
    font-size: 14px;
    position: relative;
    margin: 0 15px !important;
    background: 0 0;
    color: #222;
    border: none;
    width: auto;
  }
  .owl-prev {
    &:after {
      content: "";
      height: 20px;
      width: 20px;
      background: url(./assets/images/icons/arrow-right.svg) no-repeat center
        center;
      transform: rotate(180deg);
      display: inline-block;
      position: absolute;
      left: -15px !important;
      top: 11px !important;
    }
    width: auto;
    font-size: 14px;
    position: relative;
    margin: 0 7px !important;
    background: 0 0;
    color: #222;
    border: none;
  }
}

.slick-arrow.slick-next,
.slick-arrow.slick-prev {
  display: block;
  position: absolute;
  bottom: -45px;
  top: auto;
  height: 26px;
  z-index: 99;
  padding: 12px 20px;
}
.slick-arrow.slick-next {
  width: 60px;
  right: 0;
  padding-left: 0;
}
.slick-arrow.slick-prev {
  width: 75px;
  right: 90px;
  left: auto;
  padding-right: 0;
}

.eyebtn {
  position: absolute;
  top: 44px;
  right: 15px;
  width: 24px;
  height: 18px;
  display: block;
  background: url(./assets/images/icons/eyecross.svg) no-repeat;
  cursor: pointer;
}

.eyebtn.eyeopen {
  background: url(./assets/images/icons/eyeopen.svg) no-repeat;
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed !important;
}

.sliderTipDays.bg_none {
  background: none;
}
img .dropwDownItem {
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  width: 240px;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
    0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 10px;
}

.popup-content {
  position: absolute;
  left: 35%;
  border: 1px solid #ccc;
  z-index: 100;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
    0px 1px 3px rgba(0, 0, 0, 0.3);
  font-size: 12px;
  border-radius: 6px;
  padding: 8px;
  min-height: 33px;
  display: none;
  top: 2px;
  width: 71%;
  line-height: 17px;
}

.popup-label:hover + .popup-content {
  display: block;
}
#checkDrop ul {
  display: flex;
  flex-wrap: wrap;
}
#checkDrop .characters {
  display: flex;
  flex-wrap: wrap;
}
#checkDrop .characters > div > div {
  display: block;
  flex: 1;
  margin-left: 10px;
  margin-right: 10;
  margin-bottom: 10px;
}
.characters > div {
  display: flex;
}
.innerPage .widgetsWrap .widgetNav ul li .dropwDownItem {
  z-index: 9;
}
#checkDrop {
  padding-top: 20px;
}



$background-color_1: var(--button);
$background-color_2: #424167;

.headingSection {
  .rightSide {
    li {
      padding: 10px 4px;
    }
  }
  .widget_head {
    min-width: 100px !important;
    ul {
      li {
        background-color: var(--header);
        gap: 5px !important;
        padding: 6px 2px !important;
      }
    }
  }
}
.widget_nav_sec {
  ul {
    li {
      background-color: $background-color_1;
    }
  }
}
.btn_link {
  width: auto;
  background: none;
  padding: 0;
  height: auto;
}
.k-i-more-vertical::before {
  content: "\e129" !important;
}
.add_navBar_hamburger .dynamic_btn ul {
  align-items: flex-start !important;
}

.slick-arrow.slick-prev,
.slick-arrow.slick-next,
.owl-carousel .slick-prev:hover,
.owl-carousel .slick-prev:focus,
.owl-carousel .slick-next:hover,
.owl-carousel .slick-next:focus {
  width: auto;
  font-size: 14px;
  color: var(--input-color-black-white);
  opacity: 1;
  display: flex !important;
}

.slick-arrow.slick-prev.slick-disabled,
.slick-arrow.slick-prev.slick-disabled:before,
.slick-arrow.slick-next.slick-disabled,
.slick-arrow.slick-next.slick-disabled:before {
  opacity: 0.5 !important;
}
.slick-arrow.slick-prev:before,
.slick-arrow.slick-next:before {
  content: "";
  height: 20px;
  width: 20px;
  background: url(./assets/images/icons/arrow-right.svg) no-repeat center center;
  transform: rotate(180deg);
  display: inline-block;
  position: absolute;
  opacity: 1 !important;
  left: 0;
}
.slick-arrow.slick-next:before {
  right: 0;
  left: auto;
  transform: rotate(0deg);
}

.characters .tableWrapNew {
  width: 100%;
  resize: both !important;
  overflow: scroll !important;
  display: block;
}
input[type="radio"] {
  accent-color: #434266;
}

/* Increase the width of the dropdown */
.dx-overlay-content.dx-dropdowneditor-overlay {
  min-width: 380px; /* Adjust to your needs */
}

/* Ensure all dropdown items are fully visible */
.dx-list-item {
  white-space: normal; /* Allow text to wrap if necessary */
}
