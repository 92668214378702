.go-to-header-section {
  padding: 20px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.go-to-header-section .go-to-header-container {
  border: 1px solid rgb(228, 224, 224);
  border-radius: 6px;
  height: 500px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.go-to-header-section .entity-list {
  border: 1px solid rgb(228, 224, 224);
  border-radius: 6px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.go-to-header-section .header-content-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.go-to-header-section .header-content {
  display: flex;
  gap: 50px;
}
.header-content .goto-header-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* gap: 16px; */
  flex: 1 0;
  height: 100px;
}
.header-content .go-to-inputs-field {
  /* gap: 20px; */
  position: relative;
}
.input_field{
  height: 76px !important;
}

.header-content .go-to-input-filed {
  padding: 8px;
  width: 300px;
  height: 40px;
  border: 1px solid var(--greyColor);
  border-radius: 4px;
}
.header-content .go-to-inputs-field-row {
  flex-direction: row !important;
  align-items: center;
  width: 300px;
  justify-content: space-between;
}
.go-to-header-section .edit_content_space > div {
  width: 300px !important;
}
.go-to-header-section .content_space > div {
  width: 200px !important;
}
.go-to-header-section .space > div {
  width: 400px !important;
}
.go-to-header-section .edit_content_space1 > div {
  width: 252px !important;
}
.goto-svg-right {
  padding: 8px 10px;
  background: var(--button-lightblack-lightblue);
  border-radius: 4px;
}
.goto-docket-svg-content {
  display: flex;
  align-items: center;
  gap: 15px;
}

.goto-obligation-sub-tab-container {
  border: 1px solid rgb(228, 224, 224);
  border-radius: 6px;
  height: auto;
  padding: 10px 0;
}
.goto-obligation-sub-tab-container .goto-obligation-sub-tab {
  display: flex;
  gap: 15px;
  align-items: center;
  margin-left: 10px;
}
.goto-obligation-button {
  background-color: var(--button-lightblack-lightblue);
  color: var(--TextColor);
  width: auto;
}
.goto-obligation-button-active {
  background-color: var(--btn-blue-dark);
  color: white;
  width: auto;
}
.common-block-active-sub-tab {
  padding: 10px;
}
.goto_obligation_change_financialStatus {
  height: 587px;
  border: 3px solid var(--button2);
  background-color: var(--popup-background-white-black);
}
.goto_obligation_change_financialStatus .input-flex-div {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  grid-column-gap: 50px;
  grid-row-gap: 8px;
}
.goto_obligation_change_financialStatus .input-flex {
  display: flex;
  align-items: center;
  gap: 10px;
}
