.financial_tab {
  width: 100%;
  display: flex;
  height: auto;
  gap: 45px;
}

.financial_tab .financial_tab_nav {
  display: flex;
  flex-direction: column;
  padding: 20px 12px;
  width: 137px;
  height: auto;
  gap: 20px;
}

.financial_tab .financial_tab_nav .financial_tab_nav_button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 8px;
  gap: 9px;
  width: 187px;
  height: 40px;
  background-color: var(--btn-grey-black);
  color: var(--white-black);
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  border: none;
}

.financial_tab .financial_tab_nav .financial_tab_active {
  background: var(--btnColor);
  color: #ffffff;
}

.financial_tab .financial_tab_data {
  width: calc(100% - 170px);
  height: auto;
  padding: 20px;
}
