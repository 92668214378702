.createApoimentPopup button {
  display: flex;
  justify-content: space-around;
  width: fit-content;
  margin: 1%;
  background: var(--btn-grey-blue-dark);
}
.createApoimentPopup button p {
  color: var(--white-black) !important;
}
.ReportDates_table {
  width: 98%;
  margin: 1%;
}

.CreateAppointment_lower_block {
  display: flex;
  width: 94%;
  margin-left: 3%;
  justify-content: space-between;
}
.CreateAppointment_lower_block .CreateAppointment_lower_block_left {
  width: 48%;
}
.CreateAppointment_lower_block .CreateAppointment_lower_block_right {
  width: 48%;
}
.CreateAppointment_lower_block_left p {
  color: var(--TextColor);
  font-weight: 530;
  font-size: 12.7px;
}
.CreateAppointment_lower_block_left .drop_downn_block {
  margin-top: 3%;
}
.drop_downn_block textarea {
  width: 100%;
  height: 140px;
}

.CreateAppointment_lower_block_right p {
  color: var(--TextColor);
  font-weight: 530;
  font-size: 12.7px;
}
.CreateAppointment_lower_block_right .drop_downn_block {
  margin-top: 3%;
}
.enterattendencesentencenode {
  width: 94%;
  margin-left: 3%;
}
.flex-attendence {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 20px;
}
