.docketSubTabs {
  display: flex;
}
.goto-subtabs {
  padding: 15px;
}
.tab-buttons-conatiner {
  display: flex;
  align-items: center;

  .tab-button {
    width: auto !important;
    cursor: pointer;
  }
}

.moreItem {
  height: 190px;
  width: 200px;
  right: 0px;
  position: absolute;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  background-color: var(--color2);
  z-index: 10;
  top: 50px;
  overflow: hidden;
}
.moreItem p {
  color: black;
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  margin-top: 3px;
  background-color: rgb(227, 241, 254);
  padding: 7px;
  cursor: pointer;
}

/* @media only screen and (max-width: 1150px) {
  .tab-buttons-conatiner {
    overflow: auto;
    scroll-snap-type: x mandatory;

    .tab-button {
      flex-shrink: 0;
      scroll-snap-align: start;
    }
  }
} */
