.AddDocketBLock{
    padding: 0px 20px 0px 20px;

}
.AddDocketBLockfield{
    display: flex;
    align-items: center;
}
.AddDocketBLockfield p{
    margin-top: 10px;
    width: 190px;
    font-weight: bold;
    font-size: 14px;
}