.topTab {
  display: flex;
  border-bottom: 1px solid #d3d3d0;
  height: 40px;
  width: 100%;
}

.topTabb {
  display: flex;
  height: 40px;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
}
.topTabbb {
  overflow-x: auto;
  white-space: nowrap;
  width: calc(100% - 12px);
}
.topTabbb::-webkit-scrollbar {
  height: 4px;
}
.topTab div {
  cursor: pointer;
  height: 40px;
  /* margin-left: 10px; */
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
}
.topTab div p {
  font-weight: bold;
}
.topTabb div {
  cursor: pointer;
  height: 40px;
  /* margin-left: 10px; */
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
}
.TopDynamicDocket {
  display: flex;
  height: 40px;
  border-bottom: 1px solid #d3d3d0;
}

.TopDynamicDocket div {
  cursor: pointer;
  height: 38.9px;
  /* margin-left: 10px; */
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
}
.TopDynamicDocket div svg {
  margin-left: 8px;
}
.TopDynamicDocket div p {
  color: var(--TextColor);
  font-weight: bold;
  padding: 10px;
}
.topTab div p {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.5);
  margin: 10px;
  margin-top: 15px;
  flex-grow: 1;
}
.topTabb div p {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  letter-spacing: 0.1px;
  color: var(--TextColor);
  margin: 10px;
  margin-top: 15px;
  flex-grow: 1;
}
.Block1 {
  width: min-content;
}

.Block1_list {
  display: flex;
}

.Block1_label {
  width: 102px;
  text-align: right;
  margin-top: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  letter-spacing: 0.1px;
  color: var(--greyColor);
}

.Block1_content {
  color: black;
  font-weight: 600;
  display: flex;
  width: 175px;
  margin-left: 3px;
}

.Block1_value {
  color: var(--TextColor);
  font-weight: 600;
  text-align: left;
  margin-top: 10px;
  margin-left: 6px;
}

.Block1_icon {
  width: 16px;
  height: 16px;
  fill: none;
  margin-top: 7%;
}
.Block2 {
  width: min-content;
}

.Block3 {
  width: min-content;
}
.Block3 .Block1_label {
  width: 128px;
}
.Block3 .Block1_value {
  width: 120px;
  font-weight: bold;
}
.Block3 .Block1_value p {
  text-align: right;
  margin-top: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.6);
}

.Block2_label {
  width: 135px;
  text-align: right;
  margin-top: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  letter-spacing: 0.1px;
  color: var(--greyColor);
}

.Block2_value {
  color: var(--TextColor);
  font-weight: 600;
  display: flex;
  margin-left: 6px;
  text-align: left;
  margin-top: 10px;
}

.collectionpopupBLOCK2 {
  display: flex;
  justify-content: space-between;
  margin-top: 4%;
}
.collectionpopupBLOCK2 .collectionpopupBLOCK2input {
  width: 30%;
}

.collectionpopupBLOCK2 .collectionpopupBLOCK2inputt {
  width: 48%;
}
.quickAddObligationn {
  display: flex;
  justify-content: space-between;
  margin-top: 5%;
}
.quickAddObligationn div {
  display: flex;
  width: 50%;
}
.quickAddObligationn div p {
  font-weight: bold;
}
.collectionpopupBLOCK2 .collectionpopupBLOCK2input p {
  color: var(--TextColor);
  font-weight: bold;
}
.collectionpopupBLOCK2 .collectionpopupBLOCK2inputt p {
  color: var(--TextColor);
  font-weight: bold;
}
.dataTab {
  display: flex;
}

.collectionpopup {
  display: flex;
  justify-content: space-between;
}

.dataTab .dataTabblock1 div {
  display: flex;
}
.dataTab .dataTabblock1 div p {
  font-size: 13px;
  font-weight: bold;
  margin-top: 10px;
}

.collectionpopup .dataTabblock1 div {
  display: flex;
}
.collectionpopup .dataTabblock1 div p {
  font-size: 13px;
  font-weight: bold;
  margin-top: 10px;
}

.write_off_amount {
  height: 570px;
  border: 3px solid var(--button2);
  background: var(--popup-background-white-black);
}
.write_off_multiple_obligation {
  height: 558px;
  border: 3px solid var(--button2);
  background: var(--popup-background-white-black);
}

.writeOffHeaderblock4 p {
  color: black;
  font-weight: bold;
}
.writeOffHeaderblock4 {
  width: 45%;
}
.writeOffHeader {
  display: flex;
  height: 40px;
  align-items: center;
  background-color: #d3d3d0;
}
.writeOffHeader p {
  margin-left: 8px;
  color: black;
  font-weight: bold;
}
.writeOffHeaderblock3sub {
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: space-between;
}
.writeOffHeaderblock3sub p {
  color: black;
  font-weight: bold;
}
.writeOffHeaderblock2 {
  display: flex;
}
.writeOffHeaderblock2 p {
  color: black;
  font-weight: bold;
  width: 15%;
  justify-content: space-between;
}
.generatePaymentPlan {
  width: 94%;
  margin-left: 3%;
}
.generatePaymentPlan div {
  display: flex;
}
.generatePaymentPlandiv {
  margin-top: 14px;
  align-items: center;
  width: 100%;
}
.generatePaymentPlan div p {
  font-size: 14px;
  font-weight: bold;
  color: var(--TextColor);
}

.Block4 .Block1_list {
  display: flex;
}
.Block4 .Block1_list p {
  text-align: right;
  margin-top: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  letter-spacing: 0.1px;
  color: var(--greyColor);
}
.docketoverviewheader {
  display: flex;
  justify-content: space-between;
}
.docketoverviewheader .Block1_list p {
  font-size: 13px;
}
.overviewbottomtab {
  display: flex;
  background-color: var(--tab-light-dark);
  margin-top: 10px;
  width: calc(100% - 12px);
}

.overviewbottomtab div p {
  margin: 6px;
  font-weight: bold;
  font-size: 13px;
  color: var(--TextColor);
}
.overviewbottomtabbutton {
  display: flex;
  margin-top: 1.5%;
  gap: 20px;
}
.overviewbottomtabbutton button {
  display: flex;
  width: auto;
  cursor: pointer;
}
.overviewbottomtabbutton button p {
  color: #424167;
  font-weight: bold;
}
.overviewbottomtable {
  margin-top: 2%;
}
.obligation_detail_popup {  
  border: 3px solid var(--button2);
  height: 418px;
}
.popup_header {
  height: 48px;
  background: var(--button2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 13px;
}
.popup_header .popup_header_title,
.popup_header_title_data {
  display: flex;
  gap: 20px;
  align-items: center;
}
.popup_header .popup_header_title p {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: white;
  letter-spacing: 0.1px;
}
.popup_header .popup_header_title_para {
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  color: #dc2626 !important;
  background: #fef2f2;
  width: 45px;
  text-align: center;
  height: 20px;
  border-radius: 4px;
}
.popup_header_title_para2 {
  margin: 5px;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  color: #dc2626 !important;
  background: #fef2f2;
  width: 45px;
  text-align: center;
  height: 20px;
  border-radius: 4px;
}
.void_payment {
  height: 447px;
  border: 3px solid var(--button2);
  background: var(--popup-background-white-black);
}
.void_change_payment {
  height: 418px;
}
.void_cc_payment {
  height: 748px;
  border: 3px solid var(--button2);
  background: var(--popup-background-white-black);
}
.void_payment .void_payment_content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px;
  padding-top: 30px;
}
.void_payment .void_payment_content .void_payment_content_infromations {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.void_payment .void_payment_content .void_payment_content_infromation {
  display: flex;
  gap: 20px;
}
.void_payment_content_infromation_heading {
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  color: var(--TextColor);
}
.void_payment_content_infromation_highlight {
  padding: 8px 16px;
  display: inline-block;
  height: 32px;
  background: #fef2f2;
  border-radius: 100px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #dc2626;
}
.void_payment_content_paras {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: var(--light-grey-white-color);
}
.void_payment_content_textarea_heading {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: var(--TextColor);
  margin-bottom: 5px;
}
.void_payment_content_textarea {
  padding: 8px;
  gap: 8px;
  width: 100%;
  height: 125px;
  border: 1px solid var(--light-grey-border-textarea);
  border-radius: 4px;
  background: transparent;
  color: var(--TextColor);
}
.void_payment_btn {
  padding: 8px;
  width: 96px;
  height: 40px;
  background: #424167;
  color: #fff;
  border-radius: 4px;
  float: right;
  margin-top: 20px;
  margin-right: 20px;
  cursor: pointer;
}
.edit_payment_btn {
  padding: 8px;
  width: 96px;
  height: 40px;
  background: var(--button2);
  color: #fff;
  border-radius: 4px;
  float: right;
  margin-right: 20px;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
}
.color_btn {
  background-color: #eef !important;
  color: #424167;
}
.error_validation {
  color: #dc2626;
}
.customize_error {
  position: absolute;
  top: 138px;
}
.error_fix {
  margin-left: 30px;
}
.select_Cash_drawer {
  width: 522px;
  height: 276px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin: 0 auto;
  margin-top: 20px;
}
.select_Cash_drawer .select_Cash_drawer_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 56px 10px 16px;
  gap: 8px;
}
.select_Cash_drawer_content_list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px;
  width: 488px;
}
.select_Cash_drawer_content_list label {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.8);
}
.edit_payment {
  height: 668px;
  border: 3px solid var(--button2);
  background-color: var(--popup-background-white-black);
}
.edit_container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  padding: 20px 30px;
  margin-bottom: -24px;
}
.custom_margin {
  margin-bottom: 0px !important;
  gap: 32px;
}
.edit_container_first {
  display: flex;
  align-items: end;
  justify-content: space-between;
  gap: 20px;
}
.Drugtextcondition {
  width: 96%;
  margin-left: 2%;
}
.Drugtextcondition p {
  color: var(--TextColor);
  font-weight: bold;
}
.Drugtextcondition_sub {
  margin-left: 2%;
}
.align_basline {
  align-items: baseline !important;
}
.edit_content_flex {
  display: flex;
  flex-direction: column;
  gap: 11px;
}
.edit_content_space > div {
  margin-top: 0% !important;
}
.edit_container .edit_container_label,
.void_cc_payment_label {
  font-weight: 530;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: var(--TextColor);
}
.alert_balance {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #dc2626;
}

.edit_container .edit_container_input {
  width: 340px;
  height: 40px;
  padding: 8px;
  border: 1px solid var(--greyColor);
  border-radius: 4px;
  /* &:disabled {
    opacity: 0.5;
    background-color: transparent !important;
  } */
}

.edit_container .dx-texteditor-input {
  width: 300px;
  height: 40px;
}

.edit_container textarea {
  width: 704px;
  height: 176px;
  border: 1px solid var(--light-grey-border-textarea);
  background: transparent;
  color: var(--TextColor);
  border-radius: 4px;
}

.edit_container .edit_container_select {
  width: 340px;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.edit_container .edit_container_second_alert,
.alert_para {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #dc2626;
}
.edit_container_second_alert {
  margin-top: -13px;
}
.void_cc_payment_container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px 30px;
}
.void_cc_payment_container .void_cc_payment_first_textarea {
  width: 250px;
  height: 130px;
  margin-top: 10px;
  border: 1px solid var(--light-grey-border-textarea);
  border-radius: 4px;
  padding: 5px;
}
.void_cc_payment_container .void_cc_payment_first_textarea p {
  font-size: 12px;
  color: var(--TextColor);
}
.void_cc_payment_container .void_cc_payment_second_textarea {
  width: 704px;
  height: 150px;
  border-radius: 4px;
  margin-top: 10px;
  border: 1px solid var(--light-grey-border-textarea);
  background: transparent;
  color: var(--TextColor);
}
.void_cc_payment_container .void_cc_payment_container_field {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 10px;
}
.void_buttons {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
  position: fixed;
  bottom: 12px;
  right: 29px;
}
.void_cc_button {
  cursor: pointer;
  background: var(--button-lightblack-lightblue);
  color: var(--icons2);
}
.void_cc_button_color {
  color: #ffffff;
  background-color: var(--button2);
}
.void_cc_payment_input {
  width: 340px;
  height: 40px;
  padding: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.marks_mandatary {
  color: #dc2626;
  font-size: large;
}
.fixed_btn {
  position: fixed;
  right: 0;
  bottom: 20px;
}
.edit_confirmation {
  width: 100%;
  height: 228px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  border: 2px solid var(--button2);
  background: var(--popup-background-white-black);
}
.edit_confirmation .edit_confirmation_content {
  padding: 0 10px;
}
.edit_confirmation .edit_confirmation_content .heading {
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  color: var(--TextColor);
}
.edit_confirmation .edit_confirmation_content .sub_heading {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--TextColor);
}
.edit_confirmation .edit_confirmation_buttons {
  display: flex;
  justify-content: end;
  gap: 20px;
  margin-right: 20px;
}
.edit_confirmation .edit_confirmation_buttons .edit_confirmation_button {
  background: #434266;
  color: #fff;
}
.dataTabblock2 .dataTabblock2sub {
  display: flex;
}
.dataTabblock2 p {
  color: black;
  font-weight: bold;
}
.dataTabblock2 {
  margin-top: 3%;
  margin-bottom: 3%;
  width: 47%;
}
.ChangeSupervisionPopup p {
  color: var(--TextColor);
  font-weight: bold;
}
.ChecklistTabbuttons {
  display: flex;
  width: 78%;
}
.ChecklistTabbuttons button {
  display: flex;
  align-items: center;
  height: 37px;
  margin-top: 8px;
  cursor: pointer;
  margin-left: 20px;
}
.ChangeSupervisionPopupbottomsub p {
  font-weight: bold;
}
.ChangeSupervisionPopupbottomsub {
  display: flex;
  margin-top: 2%;
  align-items: center;
  gap: 20px;
}
.ChangeSupervisionPopupbottom {
  margin-top: 3%;
}
.ChangeSupervisionPopup {
  margin-top: 2%;
}
.dark-mode-textarea {
  border: 1px solid var(--light-grey-border-textarea) !important;
  background: transparent;
  color: var(--TextColor);
}
