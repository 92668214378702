.financial-page {
  margin-top: 20px;
  background: var(--color2);
}
.financial-page-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: var(--color2);
}
.fixed-width-lable {
  width: 170px;
}
.popup-container-data {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}
.change-check-number {
  height: 208px;
  border: 3px solid var(--button2);
  background-color: var(--popup-background-white-black);
}
.print-check-popup {
  height: 668px;
  border: 3px solid var(--button2);
  background-color: var(--popup-background-white-black);
}
.print-check-popup .edit_container_first {
  justify-content: initial;
  align-items: center;
}

