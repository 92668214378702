.document-wizard-page-section {
  background-color: var(--color2);
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  padding: 20px;
}
.document-wizard-info-section {
  display: flex;
  gap: 10px;
}
.document-wizard-info-part {
  width: 50%;
}
.document-wizard-page {
  border: 1px solid rgb(228, 224, 224);
  border-radius: 6px;
  margin-bottom: 15px;
  padding: 10px;
}
.document-wizard-content {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
.document-wizard-content-left {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.document-wizard-buttons {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.document-wizard-sub-tab {
  padding: 20px 10px;
}
.document-wizard-tabs-content {
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.add-as-attachment {
  width: 100%;
  height: 198px;
  border: 3px solid var(--button2);
}
.add-as-attachment-content {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px;
}
.add-as-attachment-content p {
  font-weight: 530;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: var(--TextColor);
}
.document-wizard-output {
  border: 1px solid rgb(228, 224, 224);
  border-radius: 6px;
  padding: 10px;
  width: 50%;
}
.document-wizardOutput-data {
  margin-top: 10px;
  /* width: fit-content;
  overflow-wrap: anywhere; */
}
.document-wizard-output-tab {
  display: flex;
  gap: 10px;
}
.document-wizard-output span {
  color: black !important;
}
.html-data-tab-name {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1px;
  display: inline;
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
  background: var(--button-lightblack-lightblue);
  color: var(--TextColor);
}

.editor-container {
  width: 100%;  /* Fixed width for the editor */
  margin: 0 auto;  /* Center the editor */
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Media query for portrait orientation */
@media screen and (orientation: portrait) {
  .editor-container {
      width: 100%;  /* Adjust the width for portrait orientation */
  }
}

/* Media query for landscape orientation */
@media screen and (orientation: landscape) {
  .editor-container {
      width: 100%;  /* Adjust the width for landscape orientation */
  }
}

/* Styles for printing */
@media print {
  .editor-container {
      width: 100%;  /* Fixed width for printing */
      padding: 0;
      border: none;
      box-shadow: none;
  }
}

.options {
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);
  margin-top: 20px;
}

.caption {
  font-size: 18px;
  font-weight: 500;
}
​
.option {
  margin-top: 10px;
}