.Docketmaidiv {
  display: flex;
  gap: 10px;
}
.Docketmaidiv .Docketmaidiv_left {
  height: 400px;
}
.Docketmaidiv .Docketmaidiv_left button {
  display: flex;
  align-items: center;
  height: 40px;
  margin: 3.5%;
  background-color: var(--btn-grey-black);
  border-radius: 4px;
  cursor: pointer;
}
.Docketmaidiv .Docketmaidiv_left .custom_btn_width {
  width: 185px;
}
.Docketmaidiv .Docketmaidiv_left button p {
  font-weight: bold;
  font-size: 13px;
  color: var(--white-black);
  margin-right: 8px;
}
.Docketmaidiv .Docketmaidiv_right {
  width: calc(100% - 179px);
  padding: 5px;
  overflow: hidden;
}

.Revocationstable {
  margin-top: 1%;
}
