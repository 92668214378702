.CustomCommonButton {
  background-color: #424167;
  color: white;
  cursor: pointer;
}
.centerDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

input[type="checkbox"]:disabled:checked {
  filter: brightness(0.1);  /* Darkens the color */
  opacity: 1; /* Ensures it's fully visible */
}

.disable-expand .dx-datagrid-group-closed::before {
  display: none; 
}