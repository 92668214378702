.popup_header {
  height: 48px;
  background: rgb(67, 66, 102);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 13px;
}
.popup_header .popup_header_title,
.popup_header_title_data {
  display: flex;
  gap: 20px;
  align-items: center;
}
.popup_header .popup_header_title p {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: white;
  letter-spacing: 0.1px;
}
.popup_header .popup_header_title_para {
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  color: #dc2626 !important;
  background: #fef2f2;
  width: 45px;
  text-align: center;
  height: 20px;
  border-radius: 4px;
}
.void_payment {
  border: 3px solid rgb(67, 66, 102);
  height: 447px;
}
.void_change_payment {
  height: 418px;
}
.void_cc_payment {
  border: 3px solid rgb(67, 66, 102);
  height: 648px;
}
.void_payment .void_payment_content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px;
  padding-top: 30px;
}
.void_payment .void_payment_content .void_payment_content_infromations {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.void_payment .void_payment_content .void_payment_content_infromation {
  display: flex;
  gap: 20px;
}
.void_payment_content_infromation_heading {
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  color: #000000;
}
.void_payment_content_infromation_highlight {
  padding: 8px 16px;
  display: inline-block;
  height: 32px;
  background: #fef2f2;
  border-radius: 100px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #dc2626;
}
.void_payment_content_paras {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.4);
}
.void_payment_content_textarea_heading {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #000000;
  margin-bottom: 5px;
}
.void_payment_content_textarea {
  padding: 8px;
  gap: 8px;
  width: 100%;
  height: 125px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.void_payment_btn {
  padding: 8px;
  width: 96px;
  height: 40px;
  background: #424167;
  color: #fff;
  border-radius: 4px;
  float: right;
  margin-top: 20px;
  margin-right: 20px;
  cursor: pointer;
}
.edit_payment_btn {
  padding: 8px;
  /* width: 96px;  */
  height: 40px;
  background: #424167;
  color: #fff;
  border-radius: 4px;
  float: right;
  margin-right: 20px;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
}
.color_btn {
  background-color: #eef !important;
  color: #424167;
}
.error_validation {
  color: #dc2626;
}
.customize_error {
  position: absolute;
  top: 138px;
}
.error_fix {
  margin-left: 30px;
}
.select_Cash_drawer {
  width: 522px;
  height: 276px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin: 0 auto;
  margin-top: 20px;
}
.select_Cash_drawer .select_Cash_drawer_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 56px 10px 16px;
  gap: 8px;
}
.select_Cash_drawer_content_list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px;
  width: 488px;
}
.select_Cash_drawer_content_list label {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.8);
}
.edit_payment {
  border: 3px solid rgb(67, 66, 102);
  height: 668px;
}
.edit_container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  padding: 20px 30px;
  margin-bottom: -24px;
}
.custom_margin {
  margin-bottom: 0px !important;
  gap: 32px;
}
.edit_container_first {
  display: flex;
  align-items: end;
  justify-content: space-between;
  gap: 20px;
}
.align_basline {
  align-items: baseline !important;
}
.edit_content_flex {
  display: flex;
  flex-direction: column;
  gap: 11px;
}
.edit_content_space > div {
  margin-top: 0% !important;
}
.edit_container .edit_container_label,
.void_cc_payment_label {
  font-weight: 530;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #000000;
}
.alert_balance {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #dc2626;
}

.edit_container .edit_container_input {
  width: 340px;
  height: 40px;
  padding: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.edit_container .dx-texteditor-input {
  width: 300px;
  height: 40px;
}

.edit_container textarea {
  width: 704px;
  height: 176px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.edit_container .edit_container_select {
  width: 340px;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.edit_container .edit_container_second_alert,
.alert_para {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #dc2626;
}
.edit_container_second_alert {
  margin-top: -13px;
}
.void_cc_payment_container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px 30px;
}
.void_cc_payment_container .void_cc_payment_first_textarea {
  width: 250px;
  height: 130px;
  margin-top: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 5px;
}
.void_cc_payment_container .void_cc_payment_first_textarea p {
  font-size: 12px;
  color: #000000;
}
.void_cc_payment_container .void_cc_payment_second_textarea {
  width: 704px;
  height: 150px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 10px;
}
.void_cc_payment_container .void_cc_payment_container_field {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 10px;
}
.void_buttons {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
  position: fixed;
  bottom: 12px;
  right: 29px;
}
.void_cc_button {
  cursor: pointer;
  /* width: auto; */
}
.void_cc_button_color {
  color: #ffffff;
  background: #424167;
}
.void_cc_payment_input {
  width: 340px;
  height: 40px;
  padding: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.marks_mandatary {
  color: #dc2626;
  font-size: large;
}
.fixed_btn {
  position: fixed;
  right: 0;
  bottom: 20px;
}
.edit_confirmation {
  width: 100%;
  height: 228px;
  border: 3px solid rgb(67, 66, 102);
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.edit_confirmation .edit_confirmation_content {
  padding: 0 10px;
}
.edit_confirmation .edit_confirmation_content .heading {
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  color: #000000;
}
.edit_confirmation .edit_confirmation_content .sub_heading {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}
.edit_confirmation .edit_confirmation_buttons {
  display: flex;
  justify-content: end;
  gap: 20px;
  margin-right: 20px;
}
.edit_confirmation .edit_confirmation_buttons .edit_confirmation_button {
  background: #434266;
  color: #fff;
}
.reallocate-payment-popup {
  height: 408px;
  border: 3px solid var(--button2);
  background: var(--popup-background-white-black);
}

.new-reallocate-payment-popup {
  height: 720px;
  width:980px;
  border: 3px solid var(--button2);
  background: var(--popup-background-white-black);
}

.flex-container {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}
