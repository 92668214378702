.admin_userlist {
  background-color: #ffffff;
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  margin-top: 30px;
}

.admin_userlist .admin_userlist_content {
  background: var(--color2);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.utf {
  width: 52px;
  height: 20px;
  background: #fef2f2;
  border-radius: 4px;
  text-align: center;
}

.utf .utf_heading {
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #dc2626;
}

.admin_userlist_content_buttons {
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
}
.admin_userlist_content_buttons .admin_userlist_button {
  display: flex;
  align-items: center;
  gap: 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  background: var(--button);
  color: var(--TextColor);
  letter-spacing: 0.25px;
}
