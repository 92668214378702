/* Custom alternating row colors */
.dx-datagrid .dx-row-alt {
    background-color: #e6f7ff; /* Light blue for alternating rows */
  }
  
  .dx-datagrid .dx-data-row {
    background-color: #ffffff; /* White for regular rows */
  }

  
  .showtotal .dx-datagrid-total-footer {
    border-bottom: 1px solid #ddd;
    display: none;
  }
  
  .dx-menu-base .dx-menu-item .dx-menu-item-content .dx-menu-item-text {
    color: black;
  }
  
  .dx-datagrid-checkbox-size .dx-checkbox-icon {
    border: 1px solid rgb(0, 0, 0);
  }
  
  .dx-datagrid-column-chooser
    .dx-overlay-content
    .dx-popup-title
    .dx-closebutton {
    margin-left: 85px !important;
  }