.supervision .image {
  width: 10%;
  text-align: center;
}
.supervision .image img {
  width: 60%;
  mix-blend-mode: darken;
}
.supervision .buttons {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
}

.supervision {
  display: flex;
  align-items: center;
}

.dx-datagrid {
  background-color: white;
}

.dx-datagrid .dx-row>td {
  padding: 12px;
}

.dx-datagrid-rowsview .dx-row > td {
  background-color: white; /* Ensure cell background is white */
}

.dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-select {
  background: white;
}

.dx-editor-outlined{
  border: none;
}