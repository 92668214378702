.participate {
  background-color: var(--dynamicTabHeader);
  color: rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid var(--outline);
  overflow-x: auto;
  white-space: nowrap;
  width: 97%;
}
/* rgb(66, 65, 103) */
.participate::-webkit-scrollbar {
  height: 4px;
}

.participate_tab_tab_block {
  cursor: pointer;
  height: 40px;
  margin-left: 10px;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
}

.TabName {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.5);
  margin: 10px;
  margin-top: 15px;
  flex-grow: 1;
}

.participate .participate_tab {
  display: flex;
}

.Buckner_block1 {
  width: 97%;
  border: 1px solid var(--outline);
  margin-left: 1.4%;
  margin-top: 1%;
  border-radius: 5px;
}

.Buckner_block1_sub {
  width: 97%;
  margin-top: 1.4%;
}

.Buckner_block1_sub button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;
  width: 184px;
  height: 35px;
  background: #eeeeff;
  border-radius: 4px;
  flex: none;
  order: 4;
  flex-grow: 0;
  margin-left: 1%;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #424167;
}

.headerBottomCheckbox {
  display: flex;
  align-items: center;
}

.custom-flag-label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: var(--input-color-black-white);
}

.bottom_checkbox input[type="checkbox"] {
  opacity: 1;
}

.participate_block1_sub1_div_blo {
  display: flex;
  flex-direction: column;
  margin-left: 6px;
}

.participate_block1_sub1_div_imag {
  height: 120px;
  border-radius: 10px;
  width: 120px;
  object-fit: cover;
}
.participate_block1_sub1_div_imagg {
  height: 120px;
  border-radius: 10px;
  width: 120px;
  object-fit: cover;
}

.upload-icon {
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.participate_block1_sub1_div_blo1 div {
  display: flex;
  width: 95%;
  margin-left: 5px;
  justify-content: space-between;
}
.participate_block1_sub1_div_blo1 div p {
  word-wrap: break-word;
  margin-top: 10px;
}

.participate_block1_sub1_div_blo2 div {
  display: flex;
  justify-content: space-between;
}
.participate_block1_sub1_div_blo2 div p {
  word-wrap: break-word;
  margin-top: 10px;
  white-space: nowrap;
}

.participate_block1_sub1_div_blo3 {
  width: 287px;
  margin-right: -22px;
}

.participate_block1_sub1_div_blo3 div {
  display: flex;
  justify-content: space-between;
}

.participate_block1_sub1_div_blo3 div p {
  width: 50%;
  word-wrap: break-word;
  margin-top: 10px;
}

.participate_block1 {
  width: 97%;
  margin-left: 1.4%;
  margin-top: 1.4%;
  border-radius: 5px;
}

.participate_block1_sub {
  width: 97%;
  margin-top: 1.4%;
  margin-left: 1%;
}

.participate_block1_sub p {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
}

.screen_num {
  padding: 0px 12px;
  gap: 8px;
  width: 45px;
  height: 20px;
  background: #fef2f2;
  border-radius: 4px;
  margin-top: -1%;
}

.screen_num p {
  color: #dc2626;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
}
.bottom_checkbox {
  display: flex;
  width: 48%;
  justify-content: space-between;
  height: 27px;
  align-items: center;
}

.bottom_checkbox_2 {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
}
.bottom_checkbox_2 div svg {
  margin-left: 3px;
  width: 15px;
}
.headerBottomCheckbox p {
  margin-left: 3px;
}

.participate_block1_sub1 {
  /* background: #ffffff; */
  /* border-radius: 8px; */
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-top: 2%;
}

.participate_block1_sub1 .participate_block1_sub1_div {
  display: flex;
  justify-content: space-between;
}

.participate_block1_sub1 div p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  letter-spacing: 0.1px;
  color: var(--input-color-black-white);
}

.participate_block1_sub button {
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;
  color: white;
  width: 120px;
  height: 40px;
  background: #424167;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
  border: none;
}

.commonBlock {
  width: 97%;
  border: 1px solid #e6e6e6;
  margin-left: 1.4%;
  margin-top: 1.4%;
  border-radius: 5px;
}

.commonBlock_bottom_border {
  height: 12px;
  background: var(--button-bottom-border);
}

.commonBlock .Buckner_block1_sub button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;
  height: 35px;
  border-radius: 4px;
  flex: none;
  order: 4;
  flex-grow: 0;
  border: none;
  margin-left: 0.83%;
  width: 6.5%;
}

.commonBlock_Details {
  display: flex;
  margin-left: 1%;
  margin-bottom: 2%;
  margin-top: 1.3%;
}

.commonBlock_Details_nav {
  width: 14%;
}

.commonBlock_Details_nav button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 8px;
  gap: 9px;
  width: 100%;
  height: 40px;
  background: #f5f5f4;
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
  border: none;
  margin-top: 8%;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  justify-content: left;
  font-weight: 600;
}

.alertpopup {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: var(--popup-background-white-black);
  gap: 32px;
  border: 2px solid var(--button2);
}
.alertpopup .alertpopup_head {
  height: 48px;
  background: var(--button2);
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 0 10px;
}

.alertpopup .alertpopup_head_title,
.alertpopup_head_title_data {
  display: flex;
  align-items: center;
  gap: 7px;
  margin-top: 1%;
}

.alertpopup .alertpopup_head_title_data p {
  margin-top: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  letter-spacing: 0.1px;
}
.alertpopup .alertpopup_head_title_data svg {
  margin-top: 6px;
}

.commonBlock_Details_nav_tab button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 8px;
  gap: 9px;
  width: 17%;
  height: 40px;
  background: #f5f5f4;
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
  border: none;
  margin-top: 0.6%;
  color: rgba(0, 0, 0, 0.6);
}

.commonBlock_Details_nav_tab {
  width: 96%;
  margin-left: 2%;
}
.commonBlock_Details_nav_tab_uper {
  display: flex;
  margin-top: 0.8%;
}

.commonBlock_Details_nav_tab p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-top: 1.5%;
  letter-spacing: 0.25px;
  margin-left: 2%;
  color: black;
}

.Detail_Address_Tab {
  width: 96%;
  margin-left: 2%;
  display: flex;
  justify-content: space-between;
}
.Detail_Notification_Tab_sub_ {
  width: 32%;
}

.input_block {
  margin-top: 7%;
  font-weight: 400;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  margin-left: 2%;
  margin-right: 2%;
}

.input_block_ {
  margin-top: 7%;
  font-weight: 400;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  margin-left: 2%;
  margin-right: 2%;
}

.input_block input {
  width: 100%;
  height: 40px;
  border-radius: 3px;
  margin-top: 2%;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
  font-size: 13.5px;
  border: 1px solid #ccc;
}

.input_block input:hover,
.input_block input:focus {
  outline: none;
  border-color: #424167;
}

.input_block_ input:hover,
.input_block_ input:focus {
  outline: none;
  border-color: #424167;
}

.input_block textarea:hover,
.input_block textarea:focus {
  outline: none;
  border-color: #424167;
}

.Detail_Home_Tab_input_block input:hover,
.Detail_Home_Tab_input_block input:focus {
  outline: none;
  border-color: #424167;
}
.Detail_Home_Tab_input_block_ input:hover,
.Detail_Home_Tab_input_block_ input:focus {
  outline: none;
  border-color: #424167;
}

.input_block p {
  color: var(--TextColor);
  font-weight: 530;
  font-size: 12.7px;
}
.input_block_ p {
  color: black;
  font-weight: 530;
  font-size: 12.7px;
}

.Detail_Co_Obligor_Tab_line1_input_block {
  margin-top: 1%;
  font-weight: 400;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  width: 32%;
}

.Detail_Co_Obligor_Tab_line1_input_block input {
  width: 100%;
  height: 36px;
  border: 1px solid #e6e6e6;
  border-radius: 3px;
  margin-top: 2%;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
  font-size: 13.5px;
}

.Detail_Co_Obligor_Tab_line1_input_block p {
  color: black;
  font-weight: 530;
  font-size: 12.7px;
}

.Detail_Co_Obligor_Tab_line1_input_block_ {
  margin-top: 1%;
  font-weight: 400;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  width: 32%;
}
.Detail_Co_Obligor_Tab_line1_input_block_ p {
  color: var(--TextColor);
  font-weight: 530;
  font-size: 12.7px;
}

.Detail_Address_Tab .Detail_Address_Tab_mail_address {
  width: 32%;
  border: 1px solid #e6e6e6;
  margin-top: 1%;
  border-radius: 5px;
  overflow: hidden;
}
.Detail_Address_Tab .Detail_Address_Tab_mail_address p {
  color: var(--TextColor);
  font-weight: 500;
}

.Detail_Address_Tab .Detail_Address_Tab_mail_address div {
  display: flex;
}
.contect_info_tab {
  width: 71%;
  margin-left: 2%;
  margin-top: 1%;
}

.Detail_Contact_Tab {
  width: 100%;
  margin-top: 0.6%;
}
.Detail_Contact_Tab .Detail_Contact_Tab_block1 {
  margin: 1.5%;
}
.Detail_Contact_Tab_block12 {
  border: 1px solid rgb(196, 3, 3);
  width: 100%;
}

.Detail_Payment_Tab {
  margin-left: 2%;
  width: 96%;
  display: flex;
  margin-top: -7px;
  justify-content: space-between;
}

.Detail_Home_Tab_input_block {
  width: 20%;
  margin-top: 1.45%;
}

.Detail_Payment_Tab_sub_input_block {
  margin-top: 9%;
}

.Detail_Payment_Tab_sub_input_block p {
  color: black;
  font-weight: 530;
  font-size: 12.7px;
}

.Detail_Payment_Tab_sub_input_block_ {
  margin-top: 9%;
}
.Detail_Payment_Tab_sub_input_block_ p {
  color: var(--TextColor);
  font-weight: 530;
  font-size: 12.7px;
}

.Detail_Home_Tab_input_block_ {
  width: 90%;
  margin-top: 1.45%;
}

.Detail_Home_Tab_input_block_ p {
  color: var(--TextColor);
  font-weight: 530;
  font-size: 12.7px;
}
.Detail_Home_Tab_input_block_ .Error {
  font-weight: normal;
}

.headerTopButton button {
  background-color: var(--button-lightblue-lightblack);
  color: var(--white-black);
}

.headerTopButton button:disabled {
  background-color: grey;
}

.Detail_Payment_Tab_sub_input_block input {
  width: 100%;
  height: 35px;
  border: 1px solid #e6e6e6;
  border-radius: 3px;
  margin-top: 3.5%;
  font-size: 13.5px;
}
.mendatrystrick {
  font-size: large;
}
.Detail_Home_Tab_input_block p {
  color: var(--TextColor);
  font-weight: 530;
  font-size: 12.7px;
}

.Detail_Home_Tab_input_block input {
  width: 100%;
  height: 35px;
  border: 1px solid #e6e6e6;
  border-radius: 3px;
  margin-top: 3.5%;
  font-size: 13.5px;
}

.select_input_block {
  margin-left: 2%;
  width: 100%;
  height: 35px;
}

.select_input_block p {
  margin-top: 7px;
}

.dropdown {
  border-radius: 3px;
  height: 130px;
  position: absolute;
  width: 100%;
  background-color: white;
  border: 1px solid #e6e6e6;
  top: calc(100% + 2px);
  overflow-x: scroll;
  overflow-x: hidden;
  z-index: 10;
}

.dropdown p {
  cursor: pointer;
}

.Error {
  color: blue;
}

.Detail_Payment_Tab .Detail_Payment_Tab_sub {
  width: 24%;
}

.Detail_Notification_Tab {
  display: flex;
  justify-content: space-around;
  margin-left: 2%;
  width: 100%;
  margin-top: 0.6%;
}

.new_data {
  width: 100%;

  margin-top: 5%;

  height: 330px;

  display: flex;

  gap: 20px;

  flex-direction: column;

  padding: 10px;
}

.Detail_Notification_Tab_sub {
  width: 96%;
  margin: 2%;
  display: flex;
  justify-content: space-between;
}

.Detail_Notification_Tab_sub .Detail_Address_Tab_mail_address {
  width: 30%;
  border: 1px solid #e6e6e6;
  margin-top: 1%;
  border-radius: 5px;
  overflow: hidden;
}
.Detail_Notification_Tab_sub .Detail_Address_Tab_mail_address p {
  color: var(--TextColor);
  font-weight: 600;
  font-size: 12.7px;
}

.Detail_Address_Tab_mail_address .Detail_Home_Tab_input_block {
  margin: 4%;
}

.Detail_Address_Tab_mail_address .Detail_Home_Tab_input_block button {
  margin-top: 6%;
  margin-bottom: 2%;
}

.Detail_Co_Obligor_Tab {
  margin-left: 2%;
  width: 85%;
}

.Detail_Co_Obligor_Tab_line1 {
  display: flex;
  justify-content: space-between;
}

.Detail_Co_Obligor_Tab_sub {
  margin-top: -12px;
}

.table_container_common_table {
  overflow: auto; /* add a scrollbar when content overflows */
  border: 1px solid #cccccc;
  height: 360px;
}

.table_container_common_table table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 10px; /* optional: add border radius */
  /* optional: add a border */
}

.table_container_common_table thead {
  position: sticky; /* make the header row sticky */
  top: 0; /* stick the header row to the top */
  z-index: 1;
  background-color: white;
}

.table_container_common_table th,
td {
  padding: 10px;
  border-bottom: 1px solid #cccccc;
}
@media only screen and (min-height: 600px) {
  .fix_participant_header {
    height: 511px;
  }
}

@media only screen and (height: 650px) {
  .fix_participant_header {
    height: 511px;
  }
}
@media only screen and (min-height: 700px) {
  .fix_participant_header {
    height: 580px;
  }
}
@media only screen and (min-height: 800px) {
  .fix_participant_header {
    height: 670px;
  }
}
@media only screen and (min-height: 900px) {
  .fix_participant_header {
    height: 1020px;
  }
}

.alert-animation-text-content {
  overflow: hidden;
  width: 97%;
  margin: 0 auto;
  margin-top: 1px;
  margin-bottom: -16px;
}

.alert-animation-text {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: red;
  animation: slideRightToLeft 12s linear;
  -webkit-animation: slideRightToLeft 12s linear;
  white-space: nowrap;
}
.alltabclose {
  margin-top: 3px;
  background-color: rgb(230, 115, 115) !important;
}

@keyframes slideRightToLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@media only screen and (max-width: 1000px) {
  @keyframes slideRightToLeft {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-222%);
    }
  }
}

@media only screen and (max-width: 1080px) {
  .alert-animation-text-content {
    margin-bottom: -7px;
  }
}
.em-subtab {
  padding: 20px 10px;
}
