.tooltip {
  background-color: var(--popup-background-white-black);
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: self-start;
  padding: 0 10px;
}
.tooltip-header {
  width: 100%;
  background-color: var(--button2);
  color: #fff;
  font-weight: 530;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-align: initial;
  padding: 10px;
}
.tooltip-info {
  display: flex;
  align-items: center;
  gap: 15px;
}
