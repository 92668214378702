.financial_overview_tab .financial_overview_tab_buttons,
.financial_overview_tab_button {
  display: flex;
  align-items: center;
  gap: 20px;
}

.financial_btn{
  gap:2px !important;
  color:#dc2626 !important;
  background: #fef2f2 !important;
  border-radius: 4px;
  /* margin-top: -1%; */
}

.financial_overview_tab_button {
  width: auto;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  cursor: pointer;
  background: var(--btn-grey-blue-dark);
  color: var(--white-black);
}

.financial_tab_table {
  margin-top: 20px;
}
.financial_tab_table .dx-datagrid-rowsview .dx-row {
  font-size: 12px;
}
.svg-color-blue{
  color:blue ;
 stroke-width: 2 !important;


  
}
