.fee-schedule-container {
  background-color: #ffffff;
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  margin-top: 30px;
}
.fee-schedule-container .fee-schedule-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: var(--color2);
}
.fee-schedule-container .fee-schedule-buttons {
  display: flex;
  justify-content: space-between;
}
.fee-schedule-buttons .primary_btn {
  display: flex;
  align-items: center;
  gap: 5px;
  width: auto;
  background-color: var(--button2);
  color: white;
}
.fee-schedule-buttons .secondary_btn {
  display: flex;
  align-items: center;
  gap: 5px;
  width: auto;
  background: var(--button-lightblack-lightblue);
  color: var(--TextColor);
  font-weight: 400;
  font-size: 14px;
}
.fee-schedule-container .fee-schedule-fileds {
  border: 1px solid rgb(228, 224, 224);
  border-radius: 6px;
  height: auto;
  padding: 10px;
  height: auto;
  display: flex;
  gap: 20px;
  /* flex-wrap: wrap; */
}

.fee-schedule-container .fee-schedule-fileds-content {
  display: flex;
  align-items: flex-end;
  gap: 24px;
  justify-content: space-between;
  width: 529px;
}
.fee-schedule-container .flex {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.fee-schedule-container .edit-content-space > div {
  width: 250px !important;
  margin-top: 0px !important;
}
.search-field {
  display: flex;
  align-items: center;
  gap: 20px;
}
.fee-schedule-find {
  height: 518px;
  border: 3px solid var(--button2);
  background-color: var(--popup-background-white-black);
}
.primary-button {
  display: flex;
  align-items: center;
  gap: 5px;
  width: auto;
  background-color: var(--button2);
  color: white;
}
.fee-schedule-find-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.large-para {
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: var(--TextColor);
}
.dds-tab {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.dds-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.dds-fields {
  display: flex;
  align-items: center;
  gap: 8px;
}

.fee-schedule-fields {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.fields-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.go-to-inputs-field {
  display: flex;
  flex-direction: column;
}

.input-width{
  width: 340px;
}

