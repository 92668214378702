input[type="date"]::-webkit-calendar-picker-indicator {
    background-image: url("../../assets2/images/icons/calenderlist.svg");
    width: 24px;
    height: 24px;
  }
  .flex {
    display: -ms-box;
    display: flex;
  }
 
  .arrearageminderMainTabWrapper {
    position: relative;
    width: 100%;
    background-color: #fff;
    padding: 0;
    border-radius: 5px;
    margin: 20px 0 30px;
    /* modal styling here */
  }
  .arrearageminderMainTabWrapper .tabwapper {
    padding: 0;
    border: 0px;
    background: var(--color2);
  }
  .arrearageminderMainTabWrapper .tabwapper .tab-menu {
    width: 100%;
    padding: 0;
  }
  .arrearageminderMainTabWrapper .tabwapper .tab-menu ul {
    list-style: none;
    border-bottom: 1px solid #ccc;
    display: -ms-box;
    display: flex;
  }
  .arrearageminderMainTabWrapper .tabwapper .tab-menu ul li {
    max-width: 100%;
    position: relative;
  }
  .arrearageminderMainTabWrapper .tabwapper .tab-menu ul li a {
    color: rbg(0, 0, 0, 0.3);
    text-transform: capitalize;
    font-weight: 500;
    display: inline-block;
    padding: 15px 24px;
    font-size: 0.875rem;
    text-decoration: none;
    position: relative;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
  }
  .arrearageminderMainTabWrapper .tabwapper .tab-menu ul li a:hover {
    color: #424167;
    text-decoration: none;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
  }
  .arrearageminderMainTabWrapper .tabwapper .tab-menu ul li a.active {
    color: #424167;
    text-decoration: none;
    padding: 15px 48px 15px 24px;
    position: relative;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
  }
  .arrearageminderMainTabWrapper .tabwapper .tab-menu ul li .arrearageminderCls {
    display: flex;
    z-index: 1;
  }
  .arrearageminderMainTabWrapper .tabwapper .tab-menu ul li .arrearageminderCls .cls {
    margin-top: 16px;
    position: relative;
    left: -30px;
    z-index: 10;
    cursor: pointer;
  }
  .arrearageminderMainTabWrapper .tabwapper .tab-menu ul li a.active::after {
    content: "";
    position: absolute;
    top: 16px;
    right: 18px;
    width: 12px;
    height: 12px;
    /* background: url('../../assets/images/icons/xclose.svg') no-repeat; */
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
  }
  .arrearageminderMainTabWrapper .tabwapper .tab-box {
    display: none;
  }
  
  .arrearageminderMainTabWrapper .tabwapper .tab-main-box {
    background: var(--color2);
    margin-top: 24px;
    padding: 0px 15px 15px;
    border-radius: 5px;
    height: 737px;
  }
  .arrearageminderMainTabWrapper .tabwapper .tab-main-box .tabcontent {
    width: 100%;
  }
  .arrearageminderMainTabWrapper .tabwapper .tab-main-box .interaction {
    margin-top: 10px;
  }
  .arrearageminderMainTabWrapper .tabwapper .tab-main-box .interaction .leftAction {
    padding: 0;
  }
  .datess {
    display: flex;
    margin-left: 5%;
    margin-top: 5px;
    width: 65%;
    justify-content: space-around;
    gap: 10px;
  }
  
  .datess div p {
    margin-top: 1%;
    color: var(--TextColor);
  }
  
  .quickDatee {
    position: absolute;
    margin-top: 14%;
    right: 0px;
    height: 120px;
    background-color: var(--input-white-black);
    overflow-y: auto;
    z-index: 10;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
      0px 2px 6px 2px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    width: 200px;
  }
  
  .quickDatee div ul {
    width: 190px;
  }
  .quickDatee div ul li {
    text-align: left;
    margin: 2%;
    cursor: pointer;
  
    width: 100%;
  }
  
  .arrearageminderMainTabWrapper .tabwapper .tab-main-box .interaction .leftAlign {
    gap: 20px;
    display: flex;
    justify-content: space-between;
  }
  
  .arrearageminderMainTabWrapper .tabwapper .tab-main-box .interaction .Docket_date {
    display: flex;
    width: 82%;
    gap: 30px;
  }
  .arrearageminderMainTabWrapper
    .tabwapper
    .tab-main-box
    .interaction
    .Docket_date
    .create {
    display: flex;
    width: 400px;
    height: 37px;
  }
  .arrearageminderMainTabWrapper
    .tabwapper
    .tab-main-box
    .interaction
    .Docket_date
    .create
    p {
    color: #000;
    font-size: 14px;
    margin-top: 10px;
    margin-right: 10px;
    margin-left: 7px;
  }
  .arrearageminderMainTabWrapper
    .tabwapper
    .tab-main-box
    .interaction
    .Docket_date
    .create
    input {
    font-size: 17px;
    width: 300px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    cursor: pointer;
  }
  .arrearageminderMainTabWrapper .tabwapper .tab-main-box .userDetails {
    margin-top: 30px;
    display: -ms-box;
    display: flex;
    justify-content: flex-start;
    gap: 30px;
  }
  .arrearageminderMainTabWrapper .tabwapper .tab-main-box .userDetails .profilePic {
    width: 160px;
    overflow: hidden;
  }
  .arrearageminderMainTabWrapper
    .tabwapper
    .tab-main-box
    .userDetails
    .profilePic
    .avatar-upload {
    position: relative;
    max-width: 160px;
    width: 160px;
    height: 160px;
  }
  .arrearageminderMainTabWrapper
    .tabwapper
    .tab-main-box
    .userDetails
    .profilePic
    .avatar-upload
    .avatar-edit {
    content: "";
    position: absolute;
    left: 40%;
    top: 40%;
    background: url("../../assets/images/icons/upload.svg") no-repeat;
    z-index: 1;
  }
  .arrearageminderMainTabWrapper
    .tabwapper
    .tab-main-box
    .userDetails
    .profilePic
    .avatar-upload
    .avatar-edit
    input {
    display: none;
  }
  .arrearageminderMainTabWrapper
    .tabwapper
    .tab-main-box
    .userDetails
    .profilePic
    .avatar-upload
    .avatar-edit
    input
    + label {
    display: inline-block;
    width: 34px;
    height: 34px;
    margin-bottom: 0;
    cursor: pointer;
  }
  .arrearageminderMainTabWrapper
    .tabwapper
    .tab-main-box
    .userDetails
    .profilePic
    .avatar-upload
    .avatar-preview {
    width: 160px;
    height: 160px;
    position: relative;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
  }
  .arrearageminderMainTabWrapper
    .tabwapper
    .tab-main-box
    .userDetails
    .profilePic
    .avatar-upload
    .avatar-preview
    > div {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .arrearageminderMainTabWrapper .tabwapper .tab-main-box .userDetails .infodetails {
    width: calc(100% - 200px);
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
  }
  .arrearageminderMainTabWrapper
    .tabwapper
    .tab-main-box
    .userDetails
    .infodetails
    .fieldWrap {
    display: inline-block;
    width: 23%;
    min-width: 299px;
  }
  .arrearageminderMainTabWrapper
    .tabwapper
    .tab-main-box
    .userDetails
    .infodetails
    .fieldWrap.widthdouble {
    width: 48%;
  }
  .arrearageminderMainTabWrapper
    .tabwapper
    .tab-main-box
    .userDetails
    .infodetails
    .fieldWrap.error {
    position: relative;
  }
  .arrearageminderMainTabWrapper
    .tabwapper
    .tab-main-box
    .userDetails
    .infodetails
    .fieldWrap.error
    label {
    color: red;
  }
  .arrearageminderMainTabWrapper
    .tabwapper
    .tab-main-box
    .userDetails
    .infodetails
    .fieldWrap.error
    input {
    border-color: red;
    color: red;
  }
  .arrearageminderMainTabWrapper
    .tabwapper
    .tab-main-box
    .userDetails
    .infodetails
    .fieldWrap.error
    input:placeholder {
    color: red;
  }
  .arrearageminderMainTabWrapper
    .tabwapper
    .tab-main-box
    .userDetails
    .infodetails
    .fieldWrap.error
    input:hover,
  .arrearageminderMainTabWrapper
    .tabwapper
    .tab-main-box
    .userDetails
    .infodetails
    .fieldWrap.error
    input:focus {
    border-color: red;
  }
  .arrearageminderMainTabWrapper
    .tabwapper
    .tab-main-box
    .userDetails
    .infodetails
    .fieldWrap.error
    .errorIcon {
    position: absolute;
    top: 45px;
    right: 6px;
  }
  .arrearageminderMainTabWrapper
    .tabwapper
    .tab-main-box
    .userDetails
    .infodetails
    .fieldWrap.error
    p.errorText {
    position: absolute;
    top: 73px;
    left: 0px;
    background-color: red;
    color: white;
    padding: 10px;
    font-size: 0.875rem;
    border-radius: 5px;
  }
  .arrearageminderMainTabWrapper
    .tabwapper
    .tab-main-box
    .userDetails
    .infodetails
    .fieldWrap:last-child {
    padding-bottom: 0;
  }
  .arrearageminderMainTabWrapper
    .tabwapper
    .tab-main-box
    .userDetails
    .infodetails
    .fieldWrap
    .checkBox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .arrearageminderMainTabWrapper
    .tabwapper
    .tab-main-box
    .userDetails
    .infodetails
    .fieldWrap
    .checkBox
    input[type="checkbox"] {
    height: 20px;
    width: 20px;
    margin-right: 12px;
    cursor: pointer;
  }
  .arrearageminderMainTabWrapper
    .tabwapper
    .tab-main-box
    .userDetails
    .infodetails
    .fieldWrap
    .checkBox
    label {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 0px;
    width: 100%;
    display: inline-block;
  }
  .arrearageminderMainTabWrapper
    .tabwapper
    .tab-main-box
    .userDetails
    .infodetails
    .fieldWrap
    label {
    font-size: 14px;
    line-height: 28px;
    margin-bottom: 7px;
    width: 100%;
    display: inline-block;
  }
  .arrearageminderMainTabWrapper
    .tabwapper
    .tab-main-box
    .userDetails
    .infodetails
    .fieldWrap
    input {
    height: 40px;
    padding: 8px;
    font-size: 0.875rem;
    line-height: 20px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  .arrearageminderMainTabWrapper
    .tabwapper
    .tab-main-box
    .userDetails
    .infodetails
    .fieldWrap
    input:hover,
  .arrearageminderMainTabWrapper
    .tabwapper
    .tab-main-box
    .userDetails
    .infodetails
    .fieldWrap
    input:focus {
    outline: none;
    border-color: #424167;
  }
  .arrearageminderMainTabWrapper
    .tabwapper
    .tab-main-box
    .userDetails
    .infodetails
    .fieldWrap
    input[type="checkbox"] {
    height: 30px;
    width: 30px;
  }
  .arrearageminderMainTabWrapper .tabwapper .tab-main-box .authentication {
    width: 100%;
    padding: 20px;
    margin-top: 30px;
    border: 1px solid #ccc;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
  }
  .arrearageminderMainTabWrapper .tabwapper .tab-main-box .authentication .fieldWrap {
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
  }
  .arrearageminderMainTabWrapper
    .tabwapper
    .tab-main-box
    .authentication
    .fieldWrap
    .checkBox
    label {
    font-size: 0.875rem;
    /* color: rgba(0, 0, 0, 0.4); */
    color: #00000099;
  }
  /* 
    .arrearageminderMainTabWrapper
      .tabwapper
      .tab-main-box
      .authentication
      .fieldWrap
      .checkBox input[type="checkbox"]:checked {
        background: black;
        color: white;
      }
    
    input[type="checkbox"] {
      cursor: pointer;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      outline: 0;
      background: white !important;;
      height: 16px;
      width: 16px;
      border: 1px solid black;
      color: white;
    }
    
    input[type="checkbox"]:after {
      content: ' ';
      position: relative;
      left: 40%;
      top: 20%;
      width: 15%;
      height: 40%;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      transform: rotate(50deg);
      display: none;
    }
    
    input[type="checkbox"]:checked:after {
      display: block;
    } */
  
  .arrearageminderMainTabWrapper .tabwapper .tab-main-box .authentication .selectMethod {
    width: 100%;
    margin-top: 24px;
  }
  .arrearageminderMainTabWrapper .tabwapper .tab-main-box .authentication .selectMethod p {
    font-size: 0.875rem;
    /* color: rgba(0, 0, 0, 0.4); */
    color: #00000099;
  }
  .arrearageminderMainTabWrapper
    .tabwapper
    .tab-main-box
    .authentication
    .selectMethod
    .method {
    margin-top: 24px;
  }
  .arrearageminderMainTabWrapper
    .tabwapper
    .tab-main-box
    .authentication
    .selectMethod
    .method
    .radioWithField {
    display: flex;
    align-items: flex-start;
    gap: 100px;
  }
  .arrearageminderMainTabWrapper
    .tabwapper
    .tab-main-box
    .authentication
    .selectMethod
    .method
    .radioWithField
    .option {
    display: block;
    position: relative;
    min-width: 320px;
  }
  .arrearageminderMainTabWrapper
    .tabwapper
    .tab-main-box
    .authentication
    .selectMethod
    .method
    .radioWithField
    .option
    .lable-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
  }
  .arrearageminderMainTabWrapper
    .tabwapper
    .tab-main-box
    .authentication
    .selectMethod
    .method
    .radioWithField
    .option
    .lable-info
    input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
    cursor: pointer;
  }
  .arrearageminderMainTabWrapper
    .tabwapper
    .tab-main-box
    .authentication
    .selectMethod
    .method
    .radioWithField
    .option
    .lable-info
    label {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    color: #000;
    padding-left: 40px;
    position: relative;
    line-height: 24px;
  }
  .arrearageminderMainTabWrapper
    .tabwapper
    .tab-main-box
    .authentication
    .selectMethod
    .method
    .radioWithField
    .option
    .lable-info
    label::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0;
    top: 0;
    border: 1px solid #8d8d8d;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
  }
  .arrearageminderMainTabWrapper
    .tabwapper
    .tab-main-box
    .authentication
    .selectMethod
    .method
    .radioWithField
    .option
    .lable-info
    input:checked
    + label::before {
    content: "";
    background: #302e2e;
    position: absolute;
    width: 10px;
    height: 10px;
    left: 6px;
    top: 6px;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
  }
  .arrearageminderMainTabWrapper
    .tabwapper
    .tab-main-box
    .authentication
    .selectMethod
    .method
    .radioWithField
    .option
    .option-control {
    display: block;
    min-height: 40px;
    padding: 8px;
    border: 1px solid #ccc;
    font-size: 0.875rem;
    width: 100%;
    line-height: 16px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    margin-left: 40px;
    margin-top: 15px;
  }
  .arrearageminderMainTabWrapper
    .tabwapper
    .tab-main-box
    .authentication
    .selectMethod
    .method
    .radioWithField
    .option
    .option-control:hover,
  .arrearageminderMainTabWrapper
    .tabwapper
    .tab-main-box
    .authentication
    .selectMethod
    .method
    .radioWithField
    .option
    .option-control:focus {
    outline: none;
    border-color: #424167;
  }
  .arrearageminderMainTabWrapper .btn {
    font-size: 0.875rem;
    display: inline-flex;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    cursor: pointer;
    background: #424167;
    border-radius: 5px;
    padding: 12px 10px;
    position: relative;
    outline: 0;
    border: 0;
    line-height: 1.4;
    width: auto;
  }
  .arrearageminderMainTabWrapper .btn.largebuton {
    padding: 12px 30px;
  }
  .arrearageminderMainTabWrapper .btn.btnlightblue {
    background: var(--button);
    color: var(--TextColor);
  }
  .arrearageminderMainTabWrapper .btn.btnlightblue:hover {
    color: #fff;
    background: var(--btnColor);
  }
  .arrearageminderMainTabWrapper .btn.btnlightblue:hover svg path {
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    fill: #fff;
  }
  .arrearageminderMainTabWrapper .btn.btnblue {
    color: #fff;
    background: #424167;
  }
  .arrearageminderMainTabWrapper .modal {
    display: none;
    position: fixed;
    z-index: 99;
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background: rgba(217, 217, 217, 0.8);
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
  }
  .arrearageminderMainTabWrapper .modal .modalWrapper {
    position: relative;
    background-color: #fff;
    margin: auto;
    width: 80%;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    overflow: hidden;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
  }
  .arrearageminderMainTabWrapper .modal .modalWrapper.modelSizeMedium {
    max-width: 768px !important;
  }
  .arrearageminderMainTabWrapper .modal .modalWrapper .modelHeader {
    background: #f5f5f4;
    padding: 12px;
    color: #000;
    line-height: 24px;
    display: -ms-box;
    display: flex;
    font-size: 0.875rem;
  }
  .arrearageminderMainTabWrapper .modal .modalWrapper .modelHeader svg {
    margin-right: 12px;
  }
  .arrearageminderMainTabWrapper .modal .modalWrapper .close {
    color: #000;
    font-size: 1.75rem;
    font-weight: 500;
    position: absolute;
    right: 16px;
    top: 6px;
    cursor: pointer;
  }
  .arrearageminderMainTabWrapper .modal .modalWrapper .close:hover,
  .arrearageminderMainTabWrapper .modal .modalWrapper .close:focus {
    color: red;
    text-decoration: none;
  }
  .arrearageminderMainTabWrapper .modal .modalWrapper .modelContent {
    padding: 30px;
  }
  .arrearageminderMainTabWrapper .modal .modalWrapper .modelContent .fieldWrap {
    position: relative;
    display: inline-block;
    width: 100%;
    padding-bottom: 20px;
  }
  .arrearageminderMainTabWrapper .modal .modalWrapper .modelContent .fieldWrap.error {
    position: relative;
    font-size: 0.875rem;
  }
  .arrearageminderMainTabWrapper
    .modal
    .modalWrapper
    .modelContent
    .fieldWrap.error
    label {
    color: red;
  }
  .arrearageminderMainTabWrapper
    .modal
    .modalWrapper
    .modelContent
    .fieldWrap.error
    input {
    border-color: red;
    color: red;
  }
  .arrearageminderMainTabWrapper
    .modal
    .modalWrapper
    .modelContent
    .fieldWrap.error
    input:hover,
  .arrearageminderMainTabWrapper
    .modal
    .modalWrapper
    .modelContent
    .fieldWrap.error
    input:focus {
    border-color: red;
  }
  .arrearageminderMainTabWrapper
    .modal
    .modalWrapper
    .modelContent
    .fieldWrap.error
    .errorIcon {
    position: absolute;
    top: 45px;
    right: 6px;
  }
  .arrearageminderMainTabWrapper
    .modal
    .modalWrapper
    .modelContent
    .fieldWrap.error
    p.errorText {
    position: absolute;
    top: 73px;
    left: 0px;
    background-color: red;
    color: white;
    padding: 10px;
    font-size: 0.875rem;
    border-radius: 5px;
  }
  .arrearageminderMainTabWrapper .modal .modalWrapper .modelContent .fieldWrap .eyebtn {
    position: absolute;
    top: 44px;
    right: 15px;
    width: 24px;
    height: 18px;
    display: block;
    background: url(../../assets/images/icons/eyecross.svg) no-repeat;
    cursor: pointer;
  }
  .arrearageminderMainTabWrapper
    .modal
    .modalWrapper
    .modelContent
    .fieldWrap
    .eyebtn.eyeopen {
    background: url(../../assets/images/icons/eyeopen.svg) no-repeat;
  }
  .arrearageminderMainTabWrapper .modal .modalWrapper .modelContent .fieldWrap label {
    font-size: 0.875rem;
    line-height: 28px;
    margin-bottom: 7px;
    width: 100%;
    display: inline-block;
  }
  .arrearageminderMainTabWrapper .modal .modalWrapper .modelContent .fieldWrap input {
    font-size: 0.875rem;
    height: 40px;
    padding: 8px;
    line-height: 20px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  .arrearageminderMainTabWrapper .modal .modalWrapper .modelContent .fieldWrap input:hover,
  .arrearageminderMainTabWrapper
    .modal
    .modalWrapper
    .modelContent
    .fieldWrap
    input:focus {
    outline: none;
    border-color: #424167;
  }
  .arrearageminderMainTabWrapper .modal .modalWrapper .modelContent .actionButton {
    width: 100%;
    display: -ms-box;
    display: flex;
    justify-content: flex-end;
    gap: 24px;
    margin-top: 4px;
  }
  
  .Detail_Contact_Tab {
    margin-left: 0 !important;
  }
  .screen_num {
    padding: 0px 12px;
    gap: 8px;
    width: 45px;
    height: 20px;
    background: #fef2f2;
    border-radius: 4px;
    margin-top: -1%;
  }
  
  .screen_num p {
    color: #dc2626;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    margin-top: 4px;
    margin-bottom: 40px;
  }
  @media screen and (max-width: 1450px) {
    .arrearageminderMainTabWrapper .tabwapper .tab-main-box .interaction .Docket_date {
      display: flex;
      width: 75%;
      gap: 25px;
    }
    .arrearageminderMainTabWrapper
      .tabwapper
      .tab-main-box
      .interaction
      .Docket_date
      .create {
      display: flex;
      width: 300px;
      height: 37px;
    }
    .arrearageminderMainTabWrapper
      .tabwapper
      .tab-main-box
      .interaction
      .Docket_date
      .create
      p {
      color: #000;
      font-size: 14px;
      margin-top: 10px;
      margin-right: 10px;
      margin-left: 7px;
    }
    .arrearageminderMainTabWrapper
      .tabwapper
      .tab-main-box
      .interaction
      .Docket_date
      .create
      input {
      font-size: 17px;
      width: 200px;
      font-weight: 400;
    }
  }
  @media screen and (max-width: 1120px) {
    .arrearageminderMainTabWrapper .tabwapper .tab-main-box .interaction .Docket_date {
      display: flex;
      width: 75%;
      gap: 15px;
    }
    .arrearageminderMainTabWrapper
      .tabwapper
      .tab-main-box
      .interaction
      .Docket_date
      .create {
      display: flex;
      width: 210px;
      height: 37px;
    }
    .arrearageminderMainTabWrapper
      .tabwapper
      .tab-main-box
      .interaction
      .Docket_date
      .create
      p {
      color: #000;
      font-size: 12px;
      margin-top: 10px;
  
      width: 180px;
    }
    .arrearageminderMainTabWrapper
      .tabwapper
      .tab-main-box
      .interaction
      .Docket_date
      .create
      input {
      font-size: 14px;
      width: 300px;
    }
    .arrearageminderMainTabWrapper .btn {
      border-radius: 5px;
      padding: 12px 10px;
      width: 45%;
    }
  }
  