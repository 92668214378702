.page-section {
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  margin-top: 30px;
}
.page-section .page-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: var(--color2);
}
.page-info-number {
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  color: #dc2626 !important;
  background: #fef2f2;
  width: 45px;
  text-align: center;
  height: 20px;
  border-radius: 4px;
}
.confirmation-forms {
  height: 258px;
  border: 3px solid var(--button2);
  background-color: var(--popup-background-white-black);
}
.confirmation-forms-container .input-flex {
  display: flex;
  align-items: center;
  gap: 15px;
}
.create-new-person {
  height: 198px;
  border: 3px solid var(--button2);
  background-color: var(--popup-background-white-black);
}
.create-new-person .input-flex {
  display: flex;
  align-items: baseline;
  gap: 15px;
}
.input-flex-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.confirmation-forms-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
}
.note-confirmation {
  height: 149px;
  border: 3px solid var(--button2);
  background-color: var(--popup-background-white-black);
}
.page-info-text-detail {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1px;
}
.page-details-search-content {
  display: flex;
  align-items: flex-start;
  gap: 32px;
}
.display-flex-div {
  display: flex;
  align-items: center;
  gap: 8px;
}
.label_width {
  width: 120px;
  text-align: right;
}
.page-section .dropdown-space > div {
  margin-top: 0px !important;
}
.remove-person-list {
  height: 198px;
  border: 3px solid var(--button2);
  background-color: var(--popup-background-white-black);
}
.remove-person-list-content {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
