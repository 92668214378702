.sendEmailPopup{
  width: 100%;
  height: 698px;
  border: 3px solid var(--button2);
}

.sendEmailBody {
  display: flex;
  padding: 30px;
  justify-content: space-between;
}

.input-field {  
  display: flex;
  width: 450px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.input {  
  width: 450px;
  height: 40px;
  padding: 8px;
  border: 1px solid var(--greyColor);
  border-radius: 4px;
}

.selectBox {
  width: 450px;
  height: 40px;
  border: 1px solid var(--greyColor);
  border-radius: 4px;
}

.checkbox-flex {  
  display: flex;
  align-items: center;
  gap: 12px;
}

.checkbox-field {
  gap: 30px;
  display: flex;
  width: 300px;
  flex-direction: row;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

textarea {  
  width: 450px;
  height: 125px;
  padding: 8px;
  border-radius: 4px;
}