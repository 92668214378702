@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
// :root {
//   --color: #434344;
//   --color2: #1d1c1c;
//   --TextColor: #ffffff;
//   --icons: #f1eaea;
//   --icons2: white;
//   --button: #000000;
//   --buttoncolor: rgb(206, 206, 206);
//   --header: black;
//   --table1: rgb(124, 124, 124);
//   --table2: rgb(63, 62, 62);
// --button2: #000000;
// }

:root {
  --color: #f6f5ff;
  --backgrounudColor: #f6f5ff;
  --color2: #fff;
  --TextColor: #222;
  --icons: black;
  --icons2: #424167;
  --button: #eeeeff;
  --button2: #424167;
  --buttoncolor: #424167;
  --header: #424167;
  --table1: #fff;
  --table2: #eeeeff;
  --greyColor: #00000099;
  --btnColor: #424167;
  --dynamicTabHeader: #ffffff;
  --dynamicTabHeadertext1: rgb(66, 65, 103);
  --dynamicTabHeadertext2: rgba(0, 0, 0, 0.5);
  --outline: #e6e6e6;
  --white-black: black;
  --tab-blue-white: #424167;
  --btn-blue-dark: #424167;
  --input-white-black: #fff;
  --input-color-black-white: #000000;
  --btn-grey-black: #f5f5f4;
  --btn-grey-blue-dark: #eeeeff;
  --table-row-light-black: #e7e5e4;
  --table-row-name-light-black: #959595;
  --table-row-text-light-black: #333;
  --table-second-row-light-black: #eeeeff;
  --active-tab-color: #00000080;
  --button-lightblue-lightblack: #e3f1fe;
  --button-lightblack-lightblue: #eeeeff;
  --button-bottom-border: #424167;
  --popup-background-white-black: #fff;
  --light-grey-white-color: #00000066;
  --light-grey-border-textarea: #0000001a;
  --datagrid-summary-item-color: #333333b3;
  --tab-light-dark: #e7e5e4;
  --scroll-color: #f1f1f1;
  --svg-blue-light: #0075ff;
  --button-lightblack-lightblue1: #fff;
  --input-placeholder-color: #00000066;
  --crumbs-light-color: #00000080;
}

[data-theme="dark"] {
  --backgrounudColor: #434344;
  --color: #434344;
  --color2: #1d1c1c;
  --TextColor: #ffffff;
  --icons: #f1eaea;
  --icons2: white;
  --button: #000000;
  --buttoncolor: rgb(206, 206, 206);
  --header: black;
  --table1: rgb(124, 124, 124);
  --table2: rgb(63, 62, 62);
  --button2: #000000;
  --greyColor: #dddddd99;
  --btnColor: #000000;
  --dynamicTabHeader: #1d1c1c;
  --dynamicTabHeadertext1: rgb(255, 255, 255);
  --dynamicTabHeadertext2: rgba(212, 211, 211, 0.5);
  --outline: #434344;
  --white-black: #fff;
  --tab-blue-white: #ffffff;
  --btn-blue-dark: #0000004d;
  --input-white-black: #454545;
  --input-color-black-white: #fff;
  --btn-grey-black: #333333;
  --btn-grey-blue-dark: #000000;
  --table-row-light-black: #000000;
  --table-row-name-light-black: #fff;
  --table-row-text-light-black: #fff;
  --table-second-row-light-black: #3f3e3e;
  --active-tab-color: #fdfdfdc2;
  --button-lightblue-lightblack: #2a2b2d;
  --button-lightblack-lightblue: #656565;
  --button-bottom-border: #4d4d4d;
  --popup-background-white-black: #2a2b2d;
  --light-grey-white-color: #ffffffab;
  --light-grey-border-textarea: #fff;
  --datagrid-summary-item-color: #fff;
  --tab-light-dark: #000000;
  --scroll-color: #6b6b6b;
  --svg-blue-light: #f1eaea;
  --button-lightblack-lightblue1: #656565;
  --input-placeholder-color: #ffffffde;
  --crumbs-light-color: #ffffffb5;
}

@font-face {
  font-family: "Readex Pro";
  src: url("./../fonts/ReadexPro-bold.eot");
  src: url("./../fonts/ReadexPro-bold.eot?#iefix") format("embedded-opentype"),
    url("./../fonts/ReadexPro-bold.woff2") format("woff2"),
    url("./../fonts/ReadexPro-bold.woff") format("woff"),
    url("./../fonts/ReadexPro-bold.ttf") format("truetype"),
    url("./../fonts/ReadexPro-bold.svg#ReadexPro-bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Readex Pro";
  src: url("./../fonts/ReadexPro-Medium.eot");
  src: url("./../fonts/ReadexPro-Medium.eot?#iefix") format("embedded-opentype"),
    url("./../fonts/ReadexPro-Medium.woff2") format("woff2"),
    url("./../fonts/ReadexPro-Medium.woff") format("woff"),
    url("./../fonts/ReadexPro-Medium.ttf") format("truetype"),
    url("./../fonts/ReadexPro-Medium.svg#ReadexPro-Medium") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Readex Pro";
  src: url("./../fonts/ReadexPro-Light.eot");
  src: url("./../fonts/ReadexPro-Light.eot?#iefix") format("embedded-opentype"),
    url("./../fonts/ReadexPro-Light.woff2") format("woff2"),
    url("./../fonts/ReadexPro-Light.woff") format("woff"),
    url("./../fonts/ReadexPro-Light.ttf") format("truetype"),
    url("./../fonts/ReadexPro-Light.svg#ReadexPro-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

* {
  font-family: "Readex Pro", sans-serif;
  font-weight: 400;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--scroll-color);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--button2);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #424167;
}

html {
  font-size: 16px;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

strong {
  font-weight: bold;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  height: calc(100vh - 64px);
  background: var(--backgrounudColor) !important;
}

ol,
ul {
  list-style: none;
}

a {
  color: var(--TextColor) !important;
  text-decoration: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Readex Pro", sans-serif;
  letter-spacing: 1px;
}

button {
  cursor: pointer;
}

$primary-color: #424167;

.innerPage {
  display: inline-block;
  width: calc(100% - 250px);
  margin: 64px auto;
  padding: 0 20px;
  background-color: var(--color);

  .breadCrumb {
    display: flex;
    width: 100%;
    background-color: transparent;
    padding: 20px 0;
    align-items: center;

    .showNotification {
      background: var(--button2);
      border-radius: 4px;
      color: #fff;
      font-size: 14px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      margin-left: auto;
      width: 172px;
      cursor: pointer;
    }

    ul {
      display: flex;

      li {
        color: var(--TextColor);
        font-size: 12px;
        line-height: 16px;

        &:first-child {
          margin-top: 1px;

          &::before {
            display: none;
          }
        }

        &:before {
          display: inline-block;
          padding-right: 1rem;
          padding-left: 1rem;
          color: var(--TextColor);
          content: ">";
          font-size: 16px;
        }

        &.active {
          color: var(--TextColor);
          cursor: pointer;
        }
      }
    }
  }

  .sliderTipDays {
    position: relative;
    width: 100%;
    background-color: var(--color2);
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 30px;

    .showNotification {
      background: #424167;
      border-radius: 4px;
      color: #fff;
      font-size: 14px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      margin-left: auto;
    }

    .closeIcon {
      position: absolute;
      top: 15px;
      right: 15px;
      z-index: 2;
      cursor: pointer;
    }

    .owl-dots {
      display: none;
    }

    .owl-nav {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .disabled {
        opacity: 0.6;
      }

      .owl-prev {
        font-size: 14px;
        position: relative;
        margin: 10px 15px;

        &:after {
          content: "";
          height: 20px;
          width: 20px;
          background: url(./../images/icons/arrow-right.svg) no-repeat center
            center;
          transform: rotate(180deg);
          display: inline-block;
          position: absolute;
          left: -21px;
          top: -3px;
        }
      }

      .owl-next {
        font-size: 14px;
        position: relative;
        margin: 10px 15px;

        &:after {
          content: "";
          height: 20px;
          width: 20px;
          display: inline-block;
          background: url(./../images/icons/arrow-right.svg) no-repeat center
            center;
          position: absolute;
          right: -21px;
          top: -3px;
        }
      }
    }

    .item {
      h2 {
        font-size: 16px;
        line-height: 24px;
        color: var(--input-color-black-white);
        margin-bottom: 15px;
        display: inline-block;
        width: 100%;
      }

      p {
        font-size: 12px;
        line-height: 20px;
        color: var(--input-color-black-white);
        display: inline-block;
        width: 100%;
      }
    }
  }

  .widgetsWrap {
    display: inline-block;
    width: 100%;
    padding: 20px;
    background-color: var(--color2);
    border-radius: 5px;

    .widgetNav {
      display: inline-block;
      width: 100%;
      margin-bottom: 20px;

      ul {
        display: inline-flex;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        gap: 30px;

        li {
          cursor: pointer;
          // background-color: #eeeeff;
          border-radius: 5px;
          padding: 10px 20px;
          position: relative;

          .link {
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--buttoncolor);
            font-size: 14px;
            line-height: 20px;
            gap: 10px;
            svg {
              path {
                fill: var(--icons2);
              }
            }
          }

          .dropwDownItem {
            position: absolute;
            top: calc(100% + 5px);
            right: 0;
            width: 240px;
            background: var(--color2);
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
              0px 2px 6px 2px rgba(0, 0, 0, 0.15);
            border-radius: 8px;
            padding: 10px;

            ul {
              display: inline-block;
              width: 100%;

              li {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 20px;
                font-size: 12px;
                background-color: transparent;

                &:hover {
                  color: #000;

                  svg {
                    path {
                      fill: #000;
                    }
                  }
                }

                .icon {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  color: var(--TextColor);

                  svg {
                    margin-right: 10px;
                  }
                }
              }
            }
          }

          &.active,
          &:hover {
            background-color: #424167;

            .link {
              color: #fff;

              svg {
                path {
                  fill: #fff;
                }
              }

              ul {
                li {
                  color: #000;

                  svg {
                    path {
                      fill: #000;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.tableWrapNew {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;

  .headingSection {
    background-color: var(--header);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 45px;
    padding: 0 20px 0 15px;

    h3 {
      color: #fff;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      svg {
        margin-right: 10px;
      }

      em {
        font-style: normal;
        min-width: 135px;
      }
    }

    .rightSide {
      ul {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;

        li {
          cursor: pointer;
        }
      }
    }
  }

  table {
    width: 100%;

    .link {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }

    tr {
      th {
        background-color: var(--color);
        padding: 12px 25px;
        font-size: 12px;
        color: var(--TextColor);
        text-align: left;
      }
      td {
        // background-color: #fff;
        background-color: var(--table1);
        padding: 12px 25px;
        font-size: 12px;
        color: var(--TextColor);
        text-align: left;
      }

      &:nth-child(2n) {
        td {
          background-color: var(--table2);
        }
      }
    }
  }
}

.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
  border-bottom: 1px solid #ddd;
  background-color: #e7e5e4;
}

.dx-datagrid-rowsview .dx-row > td {
  border-bottom: 1px solid rgb(206, 201, 201);
}

.dx-datagrid .dx-column-lines > td {
  border-left: 1px solid white !important;
  border-right: 1px solid white !important;
}
.dx-button-has-text .dx-button-content .dx-button-text {
  color: black;
}

.dx-datagrid .dx-column-indicators .dx-sort-index-icon {
  display: none;
}

.dx-overlay-content .dx-popup-content {
  padding: 0px;
}

#gridContainer {
  font-size: smaller;
}

.dx-datagrid .dx-header-filter {
  color: rgb(65, 64, 64) !important;
}
.dx-datagrid .dx-header-filter-empty {
  color: rgb(182, 181, 181) !important;
}
.dx-datagrid .dx-sort-down {
  color: rgb(44, 44, 44);
}

.dx-datagrid .dx-sort-up {
  color: rgb(44, 44, 44);
}

.dx-pager .dx-light-pages .dx-info-text,
.dx-pager .dx-light-pages .dx-page-index,
.dx-pager .dx-light-pages .dx-pages-count {
  display: table-cell;
  color: black;
}

.dx-pager .dx-light-pages .dx-info-text {
  color: black;
}

.dx-treeview-select-all-item .dx-checkbox-text {
  color: rgb(10, 10, 10);
}
.dx-pager.dx-light-mode .dx-page-indexes {
  margin-right: -10px;
}

.dx-calendar-body td span {
  color: black;
}

.dx-toolbar .dx-toolbar-after {
  left: 0;
}
.dx-datagrid-header-panel .dx-toolbar {
  background: var(--color2) !important;
}
// remove margin of search in multidropdown
.dx-datagrid-filter-row .dx-editor-cell .dx-menu {
  margin: 0 !important;
}
.dx-texteditor-input,
input {
  color: var(--TextColor) !important;
}
// input::placeholder {
//   font-weight: 400;
//   font-size: 14px;
//   line-height: 20px;
//   letter-spacing: 0.25px;
//   color: var(--input-placeholder-color) !important;
// }
textarea::placeholder {
  color: #cecece;
}

input:disabled {
  opacity: 0.5;
  // background-color: rgb(255, 255, 255);
  background: transparent;
}

textarea:disabled {
  opacity: 0.5;
  // background-color: rgb(255, 255, 255);
  background: transparent;
}

textarea {
  border: 1px solid var(--light-grey-border-textarea);
  background: transparent;
  color: var(--TextColor);
}

.svg-blue-dark {
  cursor: pointer;
  path {
    fill: var(--icons2);
  }
}
.svg-blue-white {
  cursor: pointer;
  path {
    fill: var(--tab-blue-white);
  }
}

.svg-black-white {
  cursor: pointer;
  path {
    fill: var(--input-color-black-white);
  }
}
.svg-blue-black {
  cursor: pointer;
  path {
    fill: var(--svg-blue-light) !important;
  }
}
.svg-white {
  cursor: pointer;
  path {
    fill: #fff;
  }
}
input {
  background: var(--input-white-black);
  color: var(--input-color-black-white);
}
/*for table row */
.dx-datagrid {
  color: var(--table-row-text-light-black) !important;
  background: var(--table1) !important;
}
/*for table header */
.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
  background: var(--table-row-light-black);
  color: var(--table-row-name-light-black);
}
/* for sub table */
.dx-datagrid-rowsview
  .dx-master-detail-row:not(.dx-datagrid-edit-form)
  .dx-master-detail-cell,
.dx-datagrid-rowsview
  .dx-master-detail-row:not(.dx-datagrid-edit-form)
  > .dx-datagrid-group-space {
  color: var(--table-row-text-light-black) !important;
  background: var(--table1) !important;
}
.dx-datagrid-summary-item {
  color: var(--datagrid-summary-item-color);
}
.dx-datagrid-summary-item {
  color: var(--TextColor) !important;
}
/*for date box */
.dx-texteditor.dx-editor-outlined {
  background: var(--input-white-black) !important;
}
/*for date text color */
.dx-datebox:not(.dx-datebox-native).dx-auto-width .dx-texteditor-input,
.dx-datebox:not(.dx-datebox-native):not(.dx-texteditor-empty).dx-auto-width
  .dx-texteditor-input {
  color: var(--TextColor) !important;
}
/*for date icon color */
.dx-datebox-date .dx-dropdowneditor-icon::before {
  color: var(--TextColor) !important;
}
/*for select date box */
.dx-popup-wrapper > .dx-overlay-content {
  background: var(--input-white-black) !important;
  border: 1px solid var(--light-grey-border-textarea) !important;
}
.dx-calendar {
  background: var(--input-white-black) !important;
}
.dx-calendar-body td span {
  color: var(--TextColor) !important;
}
.dx-button-mode-contained {
  background: var(--input-white-black) !important;
}
.dx-button-has-text .dx-button-content .dx-button-text {
  color: var(--TextColor) !important;
}
.dx-list-select-all-label {
  color: var(--TextColor) !important;
}
.dx-list .dx-empty-message,
.dx-list-item-content {
  color: var(--TextColor) !important;
}
.dx-searchbox .dx-placeholder::before,
.dx-searchbox .dx-texteditor-input {
  color: var(--TextColor) !important;
}
.dx-button-mode-contained .dx-icon {
  color: var(--TextColor) !important;
}
.dx-button-mode-contained {
  border-color: var(--TextColor) !important;
}
.dx-dropdowneditor.dx-dropdowneditor-field-clickable,
.dx-dropdowneditor.dx-dropdowneditor-field-clickable .dx-texteditor-input {
  color: var(--TextColor) !important;
}
.dx-treeview-select-all-item .dx-checkbox-text {
  color: var(--TextColor) !important;
}
.dx-treeview .dx-empty-message {
  color: var(--TextColor) !important;
}
.dx-treeview-node .dx-item .dx-treeview-item-content {
  color: var(--TextColor) !important;
}
.dx-treeview-toggle-item-visibility::before {
  color: var(--TextColor) !important;
}
.dx-selectbox-popup-wrapper .dx-list {
  background: var(--input-white-black) !important;
}
.dx-context-menu .dx-submenu {
  background-color: var(--color2) !important;
}
.dx-menu-base .dx-menu-item .dx-menu-item-content .dx-menu-item-text {
  color: var(--TextColor) !important;
}
.dx-menu-base .dx-menu-item .dx-menu-item-content .dx-icon {
  color: var(--TextColor) !important;
}
.dx-field-item-help-text,
.dx-field-item-label-text {
  color: var(--TextColor) !important;
}
.dx-scheduler-appointment-popup .dx-popup-title {
  background-color: var(--button2) !important;
  color: #fff !important;
}
.dx-scheduler-appointment-tooltip-wrapper
  .dx-overlay-content
  .dx-popup-content
  .dx-list-item {
  border: 1px solid var(--button2) !important;
}
.dx-tooltip-wrapper.dx-popover-wrapper .dx-popover-arrow::after {
  border: 1px solid var(--button2) !important;
  background: var(--input-white-black) !important;
}
.dx-button-mode-text .dx-icon {
  color: var(--icons2) !important;
}
.dx-toolbar-label .dx-toolbar-item-content > div {
  color: var(--icons2) !important;
}
.dx-datagrid-column-chooser
  .dx-overlay-content
  .dx-popup-content
  .dx-column-chooser-item {
  background-color: var(--color2) !important;
}
.dx-editor-cell .dx-texteditor,
.dx-editor-cell .dx-texteditor .dx-texteditor-input {
  background: transparent !important;
}

body[data-theme="dark"] .dx-datagrid-table.dx-datagrid-table-fixed{
  color:#000 !important 
}
