.admin-profile-section {
  margin-top: 20px;
}
.admin-profile-section .admin-profile-information-container {
  padding: 20px;
}
.admin-profile-section .utf {
  width: 52px;
  height: 20px;
  background: #fef2f2;
  border-radius: 4px;
  text-align: center;
}
.admin-profile-section .utf_heading {
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #dc2626;
}

.admin-profile-section .admin-profile-information-buttons {
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: space-between;
}
.admin-profile-section .reset-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  width: 165px;
  height: 40px;
  background: var(--button);
  color: var(--buttoncolor);
  border-radius: 4px;
  cursor: pointer;
}
.admin-profile-section .edit-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;
  width: auto;
  height: 40px;
  background: var(--button2);
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}

.admin-profile-section .admin-profile-information-content {
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 32px;
  width: 100%;
  margin-top: 24px;
}
.admin-profile-section .first-edit-content {
  display: flex;
  gap: 27px;
}
.admin-profile-section .inputs-container {
  width: calc(100% - 100px) !important;
  display: flex;
  gap: 30px !important;
  flex-wrap: wrap;
  @media only screen and (min-width: 1441px) {
    width: calc(100% - 200px) !important;
  }
}
.admin-profile-section .inputs-container.admin-layout{
  @media only screen and (min-width: 1441px) {
    width: calc(100% - 100px) !important;
  }
}

.admin-profile-section .inputs-container.admin-layout .edit-input{
  width: calc(33.33% - 20px);
}

.admin-profile-section .inputs-container.admin-layout .edit-input .edit-input-field{
  width: 100%;
}

.admin-profile-section .inputs-container.admin-layout .admin-tabs-content-data-input{
  width: calc(16.2% - 20px);
}
.admin-profile-section .inputs-container.admin-layout .admin-tabs-content-data-input .edit-input-field{
  width: 100%;
}


.admin-profile-section .edit-input {
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 5px;
  width: 240px;
  height: 64px;
}
.admin-profile-section .edit-input-label {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: var(--TextColor);
}
.admin-profile-section .edit-input-span {
  font-size: 17px;
  color: red;
}
.admin-profile-section .admin-role-select {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  width: 241px;
  height: 40px;
  border: 1px solid var(--greyColor);
  border-radius: 4px;
  background: var(--input-white-black);
  color: var(--input-color-black-white);
}
.admin-profile-section .admin-role-select > div {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.8);
}

.admin-profile-section .edit-checkbox-container {
  display: flex;
  flex-direction: row;
  padding: 0px;
  gap: 32px;
  margin-top: 30px;
}
.admin-profile-section .edit-checkbox {
  display: flex;
  flex-direction: row;
  padding: 0px;
  gap: 12px;
  align-items: center;
}
.admin-profile-section .edit-input-label {
  color: var(--TextColor);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
}
.admin-profile-section .second-edit-content {
  border: 1px solid var(--greyColor);
  border-radius: 12px;
  width: 100%;
  padding: 20px;
}
.admin-profile-section .enable-auth {
  display: flex;
  align-items: center;
  padding: 0px;
  gap: 8px;
  height: 20px;
}
.admin-profile-section .edit-input-label {
  color: var(--TextColor);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.admin-profile-section .third-edit-content {
  border: 1px solid var(--greyColor);
  border-radius: 12px;
  padding: 30px 20px;
}
.admin-profile-section .admin-tabs {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 398px;
  height: 40px;
}
.admin-profile-section .admin-tab {
  width: 88px;
  height: 40px;
  padding: 6px 16px;
  cursor: pointer;
  color: var(--TextColor);
}
.admin-profile-section .active {
  border-bottom: 4px solid var(--buttoncolor);
}

.admin-profile-section .admin-tabs-content-data {
  display: flex;
  flex-wrap: wrap;
  /* gap: 36px; */
  grid-row-gap: 16px;
  margin-top: 30px;
}
.admin-profile-section .admin-tabs-content-data-input {
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 8px;
  width: 304px;
  height: 64px;
}

.admin-profile-section .edit-input-label {
  color: var(--TextColor);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.admin-profile-section .edit-input-field {
  padding: 8px;
  width: 240px;
  height: 40px;
  border: 1px solid var(--greyColor);
  border-radius: 4px;
  /* &:disabled {
          opacity: 0.5;
          background-color: transparent !important;
        } */
}

.admin-profile-section .first-edit-content .profilePic {
  width: 160px;
  /* overflow: hidden; */
}
.first-edit-content .profilePic .avatar-upload {
  position: relative;
  max-width: 160px;
  width: 160px;
  height: 160px;
}
.first-edit-content .profilePic .avatar-upload .avatar-edit {
  content: "";
  position: absolute;
  left: 40%;
  top: 40%;
  background: url("../../assets/images/icons/upload.svg") no-repeat;
  z-index: 1;
}
.first-edit-content .profilePic .avatar-upload .avatar-edit input {
  display: none;
}
.first-edit-content .profilePic .avatar-upload .avatar-edit input + label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  cursor: pointer;
}
.first-edit-content .profilePic .avatar-upload .avatar-preview {
  width: 160px;
  height: 160px;
  position: relative;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
}
.first-edit-content .profilePic .avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
