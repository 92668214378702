.popup-container-data .dx-texteditor-input {
  /* width: 300px; */
  height: 40px;
}
.popup-container .dx-texteditor-input {
  height: 40px;
}
.scram-Control-Panel {
  height: 498px;
  border: 3px solid var(--button2);
  background-color: var(--popup-background-white-black);
}
.add-Equipment {
  height: 618px;
  border: 3px solid var(--button2);
  background-color: var(--popup-background-white-black);
}
.import-equipment {
  height: 488px;
  border: 3px solid var(--button2);
  background-color: var(--popup-background-white-black);
}
.import-field-flex {
  display: flex;
  gap: 20px;
  align-items: center;
}
.import-margin-top {
  margin-top: 20px;
}
.popup-container-data textarea {
  resize: none;
}
.import-equipment .file-upload {
  padding: 5px;
  position: relative;
  border-radius: 4px;
  width: 640px;
  border: 1px solid var(--light-grey-border-textarea);
}
.import-equipment .file-select {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
}
.import-equipment .file-select-name {
  font-family: Readex Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: var(--TextColor);
}
.import-equipment .file-select input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}
.import-equipment .file-remove {
  position: absolute;
  right: 45px;
  top: 8px;
}
.referral-preview {
  height: 618px;
  border: 3px solid var(--button2);
  background-color: var(--popup-background-white-black);
}
.referral-preview textarea {
  height: 475px;
}
.add-new-rate {
  height: 618px;
  background-color: var(--popup-background-white-black);
}
.remove-from-list {
  height: 168px;
  border: 3px solid var(--button2);
  background-color: var(--popup-background-white-black);
}
.link-person-confirm {
  height: 168px;
  border: 3px solid var(--button2);
  background-color: var(--popup-background-white-black);
}
.agency-column-fields {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
