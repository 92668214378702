.administration {
  background: var(--color2);
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  margin-top: 30px;
}

.administration_block_2 {
  display: flex;
  gap: 40px;
}

.administration_block_2_sub {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 351px;
  height: 40px;
  align-items: center;
  padding-left: 10px;
}

.administration_block_2_sub_para {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: var(--TextColor);
}

.admin_Data_Action_Icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 10px;
  gap: 24px;
}

.admin_Add_Table {
  height: 100%;
  border: 2px solid var(--button2);
  background: var(--popup-background-white-black);
}

.admin_Add_Table_head {
  height: 48px;
  background: var(--button2);
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 0 10px;
}

.admin_Add_Table_head_title,
.admin_Add_Table_head_title_data {
  display: flex;
  gap: 20px;
}

.admin_Add_Table_head_title_data p {
  margin-top: 14px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: white;
  letter-spacing: 0.1px;
}

.admin_Add_Table_head_title_data span {
  color: #fff !important;
  width: 200px;
  margin-top: 13px;
}

.admin_Add_Table_head_para {
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  color: #dc2626;
  background: #fef2f2;
  width: 45px;
  text-align: center;
  height: 20px;
  border-radius: 4px;
  margin-top: 14px;
}

.admin_Add_Table_Inputs {
  width: 550px;
  margin: 0 auto;
}

.admin_Add_Table_Inputs_field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  margin-top: 24px;
}

.admin_Add_Table_Inputs_field label {
  font-weight: 530;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: var(--TextColor);
}

.admin_Add_Table_Inputs_field .inputs {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 8px;
  outline: none;
}

.admin_Add_Table_Buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  padding-right: 25px;
  margin: 20px 0;
}
.admin_customize_buttons {
  padding-right: 0 !important;
}

.admin_Add_Table_Buttons .primary_btn {
  background: var(--button-lightblack-lightblue);
  color: var(--icons2);
  border-radius: 4px;
  padding: 8px 12px;
  width: 77px;
  height: 40px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  cursor: pointer;
}

.admin_Add_Table_Buttons .secondary_btn {
  background: var(--button2);
  border-radius: 4px;
  width: 96px;
  height: 40px;
  padding: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #ffffff;
  cursor: pointer;
}

/* .dx-popup-content,
.dx-popup-wrapper > .dx-overlay-content {
  height: auto !important;
} */

.delete_content_sub_heading {
  font-weight: 400;
  font-size: 28px;
  color: var(--TextColor);
  margin: 25px;
}

.delete_btn {
  padding: 8px;
  width: 127px;
  height: 40px;
  background: var(--button2);
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
}
.erorr_delete {
  padding: 20px;
  color: var(--TextColor);
}

.add_table_data {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  width: 85px;
  height: 40px;
  background: var(--button2);
  border-radius: 4px;
  color: #fff;
}

/* admin dropdown */
.admin_Dropdown {
  padding: 2px 8px;
  width: 311px;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.admin_Dropdown_Content {
  display: flex;
}

.admin_Dropdown_content_select {
  /* font-weight: 400; */
  /* font-size: 14px; */
  /* line-height: 20px; */
  /* letter-spacing: 0.25px; */
  color: #000000;
}

.admin_dropdown {
  border-radius: 3px;
  height: 130px;
  position: absolute;
  width: 300px;
  background-color: white;
  border: 1px solid #e6e6e6;
  margin-top: 0.3%;
  margin-left: -10px;
  overflow-x: scroll;
  overflow-x: hidden;
  z-index: 10;
}

.admin_dropdown li {
  list-style: none;
  padding-left: 5px;
  font-size: 12px;
  cursor: pointer;
}

.error_Message {
  color: #dc2626;
}

@media only screen and (max-width: 976px) {
  /* .dx-popup-content,
  .dx-popup-wrapper > .dx-overlay-content {
    width: 50vw !important;
    height: auto !important;
  } */

  .admin_Add_Table_Inputs {
    width: 40vw;
    margin: 0 auto;
  }
  .delete_content_sub_heading {
    font-size: 20px;
    margin-left: 25px;
  }
}

@media only screen and (max-width: 486px) {
  .admin_Add_Table_Buttons {
    flex-direction: column;
    padding-right: 0 !important;
    justify-content: center;
  }
  .admin_Add_Table_Buttons .primary_btn {
    width: 125px;
  }
  .delete_content_sub_heading {
    font-size: 16px;
    margin-left: 5px !important;
  }
  .administration_block_2 {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .admin_Dropdown {
    width: 199px;
  }
  .admin_dropdown {
    width: 200px !important;
  }
}
.admin_tableData p {
  float: left !important;
}

.dx-checkbox.dx-state-readonly .dx-checkbox-icon {
  border-color: #000000;
  color: #000000;
}
.admin_dropdown li.selected {
  background-color: #424167;
  color: #fff;
}
