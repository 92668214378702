/* Mixin */
.flex {
  display: -ms-box;
  display: flex;
}

/* .innerPage {
  display: inline-block;
  width: calc(100% - 80px);
  margin: 64px 0 0 80px;
  padding: 0 20px;
} */

.mainTabWrapper {
  position: relative;
  width: 100%;
  background-color: #fff;
  padding: 0;
  border-radius: 5px;
}
.mainTabWrapper .tabwapper {
  padding: 0;
  border: 0px;
  background: var(--color2);
}
.mainTabWrapper .tabwapper .tab-menu {
  width: 100%;
  padding: 0;
}
.mainTabWrapper .tabwapper .tab-menu ul {
  list-style: none;
  border-bottom: 1px solid #ccc;
  display: -ms-box;
  display: flex;
}
.mainTabWrapper .tabwapper .tab-menu ul li {
  max-width: 100%;
  position: relative;
}
.mainTabWrapper .tabwapper .tab-menu ul li a {
  color: rbg(0, 0, 0, 0.3);
  text-transform: capitalize;
  font-weight: 500;
  display: inline-block;
  padding: 15px 24px;
  font-size: 0.875rem;
  text-decoration: none;
  position: relative;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
}
.mainTabWrapper .tabwapper .tab-menu ul li a:hover {
  color: #424167;
  text-decoration: none;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
}
.mainTabWrapper .tabwapper .tab-menu ul li a.active {
  color: #424167;
  text-decoration: none;
  padding: 15px 48px 15px 24px;
  position: relative;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
}
.mainTabWrapper .tabwapper .tab-menu ul li a.active::after {
  content: "";
  position: absolute;
  top: 16px;
  right: 18px;
  width: 12px;
  height: 12px;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
}
.mainTabWrapper .tabwapper .tab-box {
  display: none;
}
.mainTabWrapper .tabwapper .tab-main-box {
  background: var(--color2);
  margin-top: 24px;
  padding: 0px 15px 15px;
  border-radius: 5px;
}
.mainTabWrapper .tabwapper .tab-main-box .tabcontent {
  width: 100%;
}
.mainTabWrapper .tabwapper .tab-main-box .interaction {
  justify-content: space-between;
}
.mainTabWrapper .tabwapper .tab-main-box .interaction .leftAction {
  padding: 0;
}
.mainTabWrapper .tabwapper .tab-main-box .userDetails {
  margin-top: 30px;
  display: -ms-box;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}
.mainTabWrapper .tabwapper .tab-main-box .userDetails .profilePic {
  width: 160px;
  overflow: hidden;
}
.mainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .profilePic
  .avatar-upload {
  position: relative;
  max-width: 160px;
  width: 160px;
  height: 160px;
}
.mainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .profilePic
  .avatar-upload
  .avatar-edit {
  content: "";
  position: absolute;
  left: 40%;
  top: 40%;
  background: url("../../assets/images/icons/upload.svg") no-repeat;
  z-index: 1;
}
.mainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .profilePic
  .avatar-upload
  .avatar-edit
  input {
  display: none;
}
.mainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .profilePic
  .avatar-upload
  .avatar-edit
  input
  + label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  cursor: pointer;
}
.mainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .profilePic
  .avatar-upload
  .avatar-preview {
  width: 160px;
  height: 160px;
  position: relative;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
}
.mainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .profilePic
  .avatar-upload
  .avatar-preview
  > div {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.mainTabWrapper .tabwapper .tab-main-box .userDetails .userInfoDetails {
  width: calc(100% - 200px) !important;
  display: flex;
  gap: 20px !important;
  flex-wrap: wrap;
}
.mainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .userInfoDetails
  .fieldWrap {
  display: inline-block;
  width: 23%;
  min-width: 250px !important;
}
.mainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .infodetails
  .fieldWrap.widthdouble {
  width: 48%;
}
.mainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .infodetails
  .fieldWrap.error {
  position: relative;
}
.mainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .infodetails
  .fieldWrap.error
  label {
  color: red;
}
.mainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .infodetails
  .fieldWrap.error
  input {
  border-color: red;
  color: red;
}
.mainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .infodetails
  .fieldWrap.error
  input:placeholder {
  color: red;
}
.mainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .infodetails
  .fieldWrap.error
  input:hover,
.mainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .infodetails
  .fieldWrap.error
  input:focus {
  border-color: red;
}
.mainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .infodetails
  .fieldWrap.error
  .errorIcon {
  position: absolute;
  top: 45px;
  right: 6px;
}
.mainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .infodetails
  .fieldWrap.error
  p.errorText {
  position: absolute;
  top: 73px;
  left: 0px;
  background-color: red;
  color: white;
  padding: 10px;
  font-size: 0.875rem;
  border-radius: 5px;
}
.mainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .infodetails
  .fieldWrap:last-child {
  padding-bottom: 0;
}
.mainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .infodetails
  .fieldWrap
  .checkBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.mainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .infodetails
  .fieldWrap
  .checkBox
  input[type="checkbox"] {
  height: 20px;
  width: 20px;
  margin-right: 12px;
  cursor: pointer;
}
.mainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .infodetails
  .fieldWrap
  .checkBox
  label {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 0px;
  width: 100%;
  display: inline-block;
}
.mainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .infodetails
  .fieldWrap
  label {
  font-size: 14px;
  line-height: 28px;
  margin-bottom: 7px;
  width: 100%;
  display: inline-block;
  color: var(--TextColor);
}
.mainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .userInfoDetails
  .fieldWrap
  input {
  height: 40px;
  padding: 8px;
  font-size: 0.875rem;
  line-height: 20px;
  width: 90% !important;
  border-radius: 5px;
  border: 1px solid var(--greyColor);
  /* &:disabled {
    opacity: 0.5;
    background-color: transparent !important;
  } */
}
.mainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .infodetails
  .fieldWrap
  input:hover,
.mainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .infodetails
  .fieldWrap
  input:focus {
  outline: none;
  border-color: #424167;
}
.mainTabWrapper
  .tabwapper
  .tab-main-box
  .userDetails
  .infodetails
  .fieldWrap
  input[type="checkbox"] {
  height: 30px;
  width: 30px;
}
.mainTabWrapper .tabwapper .tab-main-box .authentication {
  width: 100%;
  padding: 20px;
  margin-top: 30px;
  border: 1px solid #ccc;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
}
.mainTabWrapper .tabwapper .tab-main-box .authentication .fieldWrap {
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
}
.mainTabWrapper
  .tabwapper
  .tab-main-box
  .authentication
  .fieldWrap
  .checkBox
  label {
  font-size: 0.875rem;
  /* color: rgba(0, 0, 0, 0.4); */
  color: #00000099;
  /* position: relative; */
}
/* 
.mainTabWrapper
  .tabwapper
  .tab-main-box
  .authentication
  .fieldWrap
  .checkBox input[type="checkbox"] {
    visibility: hidden;
  }
.mainTabWrapper
  .tabwapper
  .tab-main-box
  .authentication
  .fieldWrap
  .checkBox input[type="checkbox"] {
    visibility: hidden;
  }
.mainTabWrapper
  .tabwapper
  .tab-main-box
  .authentication
  .fieldWrap
  .checkBox input[type="checkbox"] + label::before {
    border: 2px solid #302E2E;
    content: "\a0";
    display: inline-block;
    font: 16px/1em sans-serif;
    height: 16px;
    margin: 0 0.75em 0 0;
    padding: 0;
    vertical-align: top;
    width: 16px;
  }
.mainTabWrapper
  .tabwapper
  .tab-main-box
  .authentication
  .fieldWrap
  .checkBox input[type="checkbox"] + label::before {
    border: 2px solid #302E2E;
    content: "\a0";
    display: inline-block;
    font: 16px/1em sans-serif;
    height: 16px;
    margin: 0 0.75em 0 0;
    padding: 0;
    vertical-align: top;
    width: 16px;
  }

.mainTabWrapper
  .tabwapper
  .tab-main-box
  .authentication
  .fieldWrap
  .checkBox input[type="checkbox"] + label::after {
    content: "";
    box-sizing: content-box;
    position: absolute;
    transform-origin: 50%;
    background-color: transparent;
    top: 50%;
    left: 4px;
    width: 9px;
    height: 3px;
    margin-top: -4px;
    border-style: solid;
    border-color: #6f0c0c;
    border-width: 0 0 3px 3px;
    border-image: none;
  } */

/* .mainTabWrapper
  .tabwapper
  .tab-main-box
  .authentication
  .fieldWrap
  .checkBox input[type="checkbox"]:checked + label::after {
    border-color: red;
  } */
/*
input[type="checkbox"] {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  background: white !important;;
  height: 16px;
  width: 16px;
  border: 1px solid black;
  color: white;
}

input[type="checkbox"]:after {
  content: ' ';
  position: relative;
  left: 40%;
  top: 20%;
  width: 15%;
  height: 40%;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(50deg);
  display: none;
}

input[type="checkbox"]:checked:after {
  display: block;
} */

.mainTabWrapper .tabwapper .tab-main-box .authentication .selectMethod {
  width: 100%;
  margin-top: 24px;
}
.mainTabWrapper .tabwapper .tab-main-box .authentication .selectMethod p {
  font-size: 0.875rem;
  /* color: rgba(0, 0, 0, 0.4); */
  color: #00000099;
}
.mainTabWrapper .tabwapper .tab-main-box .authentication .selectMethod .method {
  margin-top: 24px;
}
.mainTabWrapper
  .tabwapper
  .tab-main-box
  .authentication
  .selectMethod
  .method
  .radioWithField {
  display: flex;
  align-items: flex-start;
  gap: 100px;
  flex-wrap: wrap;
}
.mainTabWrapper
  .tabwapper
  .tab-main-box
  .authentication
  .selectMethod
  .method
  .radioWithField
  .option {
  display: block;
  position: relative;
  min-width: 320px;
}

.mainTabWrapper
  .tabwapper
  .tab-main-box
  .authentication
  .selectMethod
  .method
  .radioWithField
  .option
  .lable-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}
.mainTabWrapper
  .tabwapper
  .tab-main-box
  .authentication
  .selectMethod
  .method
  .radioWithField
  .option
  .lable-info
  input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 1;
  margin: 0;
  cursor: pointer;
}
.mainTabWrapper
  .tabwapper
  .tab-main-box
  .authentication
  .selectMethod
  .method
  .radioWithField
  .option
  .lable-info
  label {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  color: #000;
  padding-left: 40px;
  position: relative;
  line-height: 24px;
}
.mainTabWrapper
  .tabwapper
  .tab-main-box
  .authentication
  .selectMethod
  .method
  .radioWithField
  .option
  .lable-info
  label::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  top: 0;
  border: 2px solid black !important;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
}
.mainTabWrapper
  .tabwapper
  .tab-main-box
  .authentication
  .selectMethod
  .method
  .radioWithField
  .option
  .lable-info
  input:checked
  + label::before {
  content: "";
  background: #302e2e;
  position: absolute;
  width: 10px;
  height: 10px;
  left: 6.5px !important;
  top: 7px !important;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
}
.mainTabWrapper
  .tabwapper
  .tab-main-box
  .authentication
  .selectMethod
  .method
  .radioWithField
  .option
  .option-control {
  display: block;
  min-height: 40px;
  padding: 8px;
  border: 1px solid #ccc;
  font-size: 0.875rem;
  width: 100%;
  line-height: 16px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  margin-top: 15px;
}
.mainTabWrapper
  .tabwapper
  .tab-main-box
  .authentication
  .selectMethod
  .method
  .radioWithField
  .option
  .option-control:hover,
.mainTabWrapper
  .tabwapper
  .tab-main-box
  .authentication
  .selectMethod
  .method
  .radioWithField
  .option
  .option-control:focus {
  outline: none;
  border-color: #424167;
}
.mainTabWrapper .btn {
  font-size: 0.875rem;
  display: inline-flex;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  cursor: pointer;
  background: #424167;
  border-radius: 5px;
  padding: 12px 10px;
  position: relative;
  outline: 0;
  border: 0;
  line-height: 1.4;
  width: 100%;
}
.mainTabWrapper .btn.largebuton {
  padding: 12px 30px;
}
.mainTabWrapper .btn.btnlightblue {
  background: var(--button);
  color: var(--buttoncolor);
}
.mainTabWrapper .btn.btnlightblue:hover {
  color: #fff;
  background: #424167;
}
.mainTabWrapper .btn.btnlightblue:hover svg path {
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  fill: #fff;
}
.mainTabWrapper .btn.btnblue {
  color: #fff;
  background: var(--button2);
}
.mainTabWrapper .btn svg {
  margin-left: 10px;
}

.mainTabWrapper .modal.active {
  display: block;
}

.avatar-edit {
  display: none;
}

.avatar-upload:hover .avatar-edit {
  display: block;
}

.mainTabWrapper .modal .modalWrapper {
  position: relative;
  background-color: var(--color2);
  margin: auto;
  overflow: hidden;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  border: 2px solid var(--button2);
  height: 453px;
}
.mainTabWrapper .modal .modalWrapper.modelSizeMedium {
  width: 100%;
}
.mainTabWrapper .modal .modalWrapper .modelHeader {
  padding: 12px;
  display: -ms-box;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  background: var(--button2);
}
.mainTabWrapper .modal .modalWrapper .modelHeader .modelHeader_content {
  display: flex;
  align-items: center;
  gap: 10px;
}
.mainTabWrapper .modal .modalWrapper .modelHeader .modelHeaderTitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #fff;
}
.mainTabWrapper .modal .modalWrapper .modelHeader .modelHeaderSubTitle {
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  color: #dc2626;
  background: #fef2f2;
  width: 45px;
  text-align: center;
  height: 20px;
  border-radius: 4px;
}

.mainTabWrapper .modal .modalWrapper .close {
  color: #000;
  font-size: 1.75rem;
  font-weight: 500;
  position: absolute;
  right: 16px;
  top: 6px;
  cursor: pointer;
}
.mainTabWrapper .modal .modalWrapper .close:hover,
.mainTabWrapper .modal .modalWrapper .close:focus {
  color: red;
  text-decoration: none;
}
.mainTabWrapper .modal .modalWrapper .modelContent {
  padding: 30px;
}
.mainTabWrapper .modal .modalWrapper .modelContent .fieldWrap {
  position: relative;
  display: inline-block;
  width: 100%;
  padding-bottom: 20px;
}
.mainTabWrapper .modal .modalWrapper .modelContent .fieldWrap.error {
  position: relative;
  font-size: 0.875rem;
}
.mainTabWrapper .modal .modalWrapper .modelContent .fieldWrap.error label {
  color: red;
}
.mainTabWrapper .modal .modalWrapper .modelContent .fieldWrap.error input {
  border-color: red;
  color: red;
}
.mainTabWrapper .modal .modalWrapper .modelContent .fieldWrap.error input:hover,
.mainTabWrapper
  .modal
  .modalWrapper
  .modelContent
  .fieldWrap.error
  input:focus {
  border-color: red;
}
.mainTabWrapper .modal .modalWrapper .modelContent .fieldWrap .errorIcon {
  opacity: 0;
}
.mainTabWrapper .modal .modalWrapper .modelContent .fieldWrap.error .errorIcon {
  position: absolute;
  top: 4px;
  opacity: 1;
}
.mainTabWrapper
  .modal
  .modalWrapper
  .modelContent
  .fieldWrap.error
  p.errorText {
  position: absolute;
  top: 73px;
  left: 0px;
  background-color: red;
  color: white;
  padding: 10px;
  font-size: 0.875rem;
  border-radius: 5px;
}
.mainTabWrapper .modal .modalWrapper .modelContent .fieldWrap label {
  font-size: 0.875rem;
  line-height: 28px;
  margin-bottom: 7px;
  width: 100%;
  display: inline-block;
}
.mainTabWrapper .modal .modalWrapper .modelContent .fieldWrap input {
  font-size: 0.875rem;
  height: 40px;
  padding: 8px;
  line-height: 20px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ccc;
}
.mainTabWrapper .modal .modalWrapper .modelContent .fieldWrap input:hover,
.mainTabWrapper .modal .modalWrapper .modelContent .fieldWrap input:focus {
  outline: none;
  border-color: #424167;
}
.mainTabWrapper .modal .modalWrapper .modelContent .actionButton {
  width: 100%;
  display: -ms-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 20px !important;
  margin-top: 4px;
}

@media screen and (max-width: 1300px) {
  .mainTabWrapper
    .tabwapper
    .tab-main-box
    .userDetails
    .userInfoDetails
    .fieldWrap {
    min-width: 250px !important;
  }
}

@media screen and (max-width: 960px) {
  .mainTabWrapper
    .tabwapper
    .tab-main-box
    .authentication
    .selectMethod
    .method
    .radioWithField {
    gap: 40px !important;
  }
}
@media screen and (max-width: 880px) {
  .mainTabWrapper .tabwapper .tab-main-box .userDetails {
    flex-direction: column !important;
  }
}

.disabled {
  opacity: 0.5;
}
