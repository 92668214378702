.adminTableButtons {
  display: flex;
  align-items: center;
  gap: 20px;
}

.tableCount {
  color: var(--TextColor);
  font-weight: 800;
  margin-top: 10px;
  float: left;
}
