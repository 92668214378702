.admin_userdata {
  background: var(--color2);
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  margin-top: 30px;
}

.admin_userdata .admin_profile_tab {
  height: 40px;
  background: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px 8px 0px 0px;
  display: flex;
  align-items: center;
  gap: 20px;
  padding-left: 20px;
}

.admin_userdata .admin_profile_tab .admin_profile_tab_container {
  display: flex;
  gap: 8px;
  align-items: center;
}

.admin_userdata .admin_profile_tab .admin_profile_tab_container p {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  color: #424167;
  cursor: pointer;
  height: 38px;
  border-bottom: 4px solid #424167;
}

.admin_profile_content {
  padding: 20px;
}

.admin_profile_content .utf {
  width: 52px;
  height: 20px;
  background: #fef2f2;
  border-radius: 4px;
  text-align: center;
}

.admin_profile_content .utf .utf_heading {
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #dc2626;
}

.admin_profile_buttons {
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: space-between;
}

.admin_profile_buttons_float {
  float: right;
}

.edit_button_content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 24px;
}

.edit_button_content .first_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  width: 90px;
  height: 40px;
  background: var(--button-lightblack-lightblue);
  color: var(--icons2);
  border-radius: 4px;
  cursor: pointer;
}

.admin_profile_buttons .edit_button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;
  width: 76px;
  height: 40px;
  background: var(--button2);
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}

.admin_userdata .admin_edit_content {
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 32px;
  width: 100%;
  margin-top: 24px;
}

.admin_edit_content .first_edit_content {
  display: flex;
  /* flex-wrap: wrap; */
  gap: 27px;
}

.admin_edit_content .first_edit_content .edit_image {
  width: 160px;
  height: 160px;
  background: #f5f5f4;
  border-radius: 8px;
  max-width: 160px;
  border: 1px dashed #000000;
}

.admin_edit_content .first_edit_content .edit_image .edit_image_upload_content {
  flex-direction: column;
  gap: 10px;
}

.edit_image .uploadImage {
  opacity: 0;
  position: absolute;
  height: 142px;
  width: 160px;
}

.edit_image .avatar-preview {
  width: 160px;
  height: 160px;
  position: relative;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
}

.edit_image .avatar-preview .imagePreviewImage {
  width: 100%;
  height: 160px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.no_border {
  border: none !important;
}

.edit_image_container .image_validation {
  color: red;
  width: 158px;
}

.edit_image .edit_image_upload_content p {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.5);
  letter-spacing: 0.1px;
}

.inputs_container.user_layout{
  @media only screen and (min-width: 1441px) {
    width: calc(100% - 100px) !important;
  }
}
.inputs_container {
  width: calc(100% - 100px) !important;
  display: flex;
  gap: 30px !important;
  flex-wrap: wrap;

  @media only screen and (min-width: 1441px) {
    width: calc(100% - 200px) !important;
  }
}

.admin_edit_content .first_edit_content .edit_input {
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 5px;
  width: 18%;
  height: 64px;
}



.admin_edit_content .first_edit_content .edit_input label span {
  font-size: 17px;
  color: red;
}

.admin_edit_content .first_edit_content .edit_input input {
  padding: 8px;
  width: 240px;
  height: 40px;
  border: 1px solid var(--greyColor);
  border-radius: 4px;
  /* &:disabled {
    opacity: 0.5;
    background-color: transparent !important;
  } */
}

.admin_edit_content .admin_role_select {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  width: 215px;
  height: 40px;
  border: 1px solid var(--greyColor);
  border-radius: 4px;
  background: var(--input-white-black);
  color: var(--input-color-black-white);
}

.admin_edit_content .admin_role_select>div {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.8);
}

.admin_edit_content .first_edit_content .edit_checkbox_container {
  display: flex;
  flex-direction: row;
  padding: 0px;
  gap: 32px;
  margin-top: 30px;
}

.edit_checkbox_container .edit_checkbox {
  display: flex;
  flex-direction: row;
  padding: 0px;
  gap: 12px;
}

.edit_checkbox_container .edit_checkbox label {
  color: var(--TextColor);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.admin_edit_content .second_edit_content {
  border: 1px solid var(--greyColor);
  border-radius: 12px;
  width: 100%;
  /* height: 220px; */
  padding: 20px;
}

.second_edit_content .enable_auth {
  display: flex;
  align-items: center;
  padding: 0px;
  gap: 8px;
  height: 20px;
}

.second_edit_content .enable_auth label {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: var(--TextColor);
}

.enable_auth_line {
  width: 99%;
  margin: 24px 0;
  height: 1px;
  background: var(--greyColor);
  border-radius: 4px;
}

.enable_auth_para {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  letter-spacing: 0.25px;
  color: var(--greyColor);
}

.enable_div {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.enable_auth_email {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding: 0px;
  gap: 8px;
  width: 268px;
  /* height: 64px; */
  margin-top: 20px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.password_admin_eye {
  display: flex;
  align-items: center;
}

.password_admin_eye .password_open_eye {
  position: relative;
  right: 30px;
  width: 24px;
  height: 18px;
  display: block;
  background: url(../../../assets/images/icons/eyecross.svg) no-repeat;
  cursor: pointer;
}

.password_admin_eye .password_close_eye {
  background: url(../../../assets/images/icons/eyeopen.svg) no-repeat;
}

.popup_content_password {
  position: absolute;
  width: 27%;
  min-height: 33px;
  /* top: 53%;
    left: 31%; */
  font-size: 12px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
    0px 1px 3px rgba(0, 0, 0, 0.3);
  display: none;
  line-height: 17px;
  z-index: 100;
}

.popup-label:hover+.popup_content_password {
  display: block;
}

.enable_auth_email .enable_auth_email_data {
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 8px;
}

.enable_auth_email .enable_auth_email_data input {
  padding: 8px;
  width: 240px;
  height: 40px;
  border: 1px solid var(--greyColor);
  border-radius: 4px;
  background: var(--input-white-black);
  color: var(--input-color-black-white);
}

.admin_edit_content .third_edit_content {
  /* height: 240px; */
  border: 1px solid var(--greyColor);
  border-radius: 12px;
  padding: 30px 20px;
}

.admin_edit_content .third_edit_content .admin_tabs {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 398px;
  height: 40px;
}

.admin_edit_content .third_edit_content .admin_tabs .admin_tab {
  text-wrap: nowrap;
  height: 40px;
  padding: 6px 16px;
  cursor: pointer;
  color: var(--TextColor);
}

.admin_edit_content .third_edit_content .admin_tabs .active {
  border-bottom: 4px solid var(--buttoncolor);
}

.admin_profile_dropdown {
  border-radius: 3px;
  height: 130px;
  position: absolute;
  width: 240px;
  background-color: white;
  border: 1px solid #e6e6e6;
  margin-top: 0.3%;
  margin-left: 0px;
  overflow-x: hidden;
  z-index: 10;
}

.admin_profile_dropdown li {
  list-style: none;
  padding-left: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}

.admin_edit_content .third_edit_content .admin_tabs_content_data {
  display: flex;
  flex-wrap: wrap;
  gap: 36px;
  /* height: 64px; */
  margin-top: 60px;
}

.admin_tabs_content_data .admin_tabs_content_data_input {
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 8px;
  width: 304px;
  height: 64px;
}

.admin_tabs_content_data .admin_tabs_content_data_input input {
  padding: 8px;
  width: 304px;
  height: 40px;
  border: 1px solid var(--greyColor);
  border-radius: 4px;
}

@media only screen and (max-width: 976px) {

  .second_edit_content,
  .third_edit_content {
    height: auto !important;
  }

  .first_edit_content,
  .edit_checkbox_container {
    flex-wrap: wrap;
  }

  .edit_checkbox_container {
    flex-direction: column !important;
  }

  .third_edit_content .admin_tabs {
    gap: 5px !important;
  }

  .admin_edit_content .third_edit_content {
    height: 530px !important;
  }

  .admin_edit_content .third_edit_content .admin_tabs .admin_tab {
    padding: 6px 9px;
  }
}

@media only screen and (max-width: 497px) {
  .admin_edit_content .first_edit_content .edit_input input {
    width: 220px !important;
  }

  .enable_auth_email {
    flex-direction: column;
  }

  .enable_auth_email .enable_auth_email_data input {
    width: 172px;
  }

  .admin_tabs_content_data .admin_tabs_content_data_input input {
    width: 175px;
  }

  .admin_edit_content .second_edit_content {
    height: 45vh !important;
    padding: 9px;
  }

  .admin_edit_content .admin_role_select {
    width: 217px;
  }

  .admin_profile_dropdown {
    width: 216px;
  }

  .admin_edit_content .third_edit_content .admin_tabs {
    flex-direction: column;
    height: auto;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .admin_edit_content .third_edit_content .admin_tabs_content_data {
    margin-top: 10px;
  }

  .admin_edit_content .third_edit_content {
    height: 70vh !important;
  }

  .admin_profile_buttons,
  .edit_button_content {
    flex-direction: column;
    gap: 24px;
  }

  .admin_profile_buttons .edit_button {
    width: 165px;
  }

  .admin_edit_content .first_edit_content .edit_image {
    margin: 0 auto;
  }
}

.radio-group {
  display: flex;
  align-items: center;
  gap: 0.2em;
}

.radio-button {
  width: 13px;
  height: 13px;
}

.first_edit_content .profilePic {
  width: 160px;
  /* overflow: hidden; */
}

.first_edit_content .profilePic .avatar-upload {
  position: relative;
  max-width: 160px;
  width: 160px;
  height: 160px;
}

.first_edit_content .profilePic .avatar-upload .avatar-edit {
  content: "";
  position: absolute;
  left: 40%;
  top: 40%;
  background: url("../../../assets/images/icons/upload.svg") no-repeat;
  z-index: 1;
}

.first_edit_content .profilePic .avatar-upload .avatar-edit input {
  display: none;
}

.first_edit_content .profilePic .avatar-upload .avatar-edit input+label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  cursor: pointer;
}

.first_edit_content .profilePic .avatar-upload .avatar-preview {
  width: 160px;
  height: 160px;
  position: relative;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
}

.first_edit_content .profilePic .avatar-upload .avatar-preview>div {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}