.dynamictabmode {
  border-bottom: 1px solid #e6e6e6;
  overflow-x: auto; 
  white-space: nowrap; 
  display: flex;
  justify-content: space-between;
}

.closebtn{
  width: 50px;
}

.dynamictabmode ul {
  display: flex; 
  list-style: none; 
  padding: 0; 
  margin: 0;
}
.dynamictabmode ul li {
  cursor: pointer;
  height: 40px;
  padding-top: 9px;
  margin-left: 10px;
  display: inline-block;
  align-items: center;
  white-space: nowrap;
}
.activee {
  color: var(--active-tab-color) !important;
}
.yesactiv {
  color: var(--tab-blue-white) !important;
}
