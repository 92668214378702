.add-edit-emperiod-popup {
  height: 828px;
  border: 3px solid var(--button2);
  background-color: var(--popup-background-white-black);
}
.add-edit-emperiod-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}
.add-edit-emperiod-container .edit_content_space > div {
  width: 310px !important;
  height: 40px !important;
}
.container-fields-heading {
  font-weight: 540;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: var(--TextColor);
}
.void-alert-popup {
  height: 168px;
  border: 3px solid var(--button2);
  background-color: var(--popup-background-white-black);
}
