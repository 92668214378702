.fee-paymentplan-section {
  display: flex;
  gap: 19px;
  overflow: hidden;
  width: 98%;
}
.fee-paymentplan-section .fee-paymentplan-content {
  display: flex;
  padding: 0 10px 10px 10px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid rgb(228, 224, 224);
  width: 335px;
}
.fee-paymentplan-section .fee-paymentplan-second-content {
  height: 410px;
}
.fee-paymentplan-content .fee-paymentplan-content-header {
  display: flex;
  width: 331px;
  padding: 12px 214px 16px 16px;
  align-items: center;
  background: var(--btn-grey-black);
  color: var(--TextColor);
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
}
.fee-paymentplan-content .fee-payment-content-info {
  display: flex;
  width: 302px;
  justify-content: space-between;
  align-items: flex-start;
}
.fee-payment-content-info-para {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: var(--greyColor);
}
.fee-payment-content-info-span {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: var(--TextColor);
}
.date_field{
  width:224px;
}
.fee-payment-content-info-input {
  width: 223px;
  height: 40px;
  padding: 8px;
  border: 1px solid var(--greyColor);
  border-radius: 4px;
}
.long-content-info-input {
  width: 423px;
  height: 40px;
  padding: 8px;
  border: 1px solid var(--greyColor);
  border-radius: 4px;
}
.fee-payment-content-info-svg {
  padding: 8px 10px;
  background: var(--button-lightblack-lightblue);
  border-radius: 4px;
  cursor: pointer;
}
.fee-paymentplan-section .go-to-inputs-field {
  height: auto !important;
}
.fee-paymentplan-section .goto-obligation-svg-content {
  display: flex;
  align-items: end;
  gap: 15px;
}
.fee-paymentplan-section .right-obligation-text {
  width: 300px;
}
.fee-paymentplan-section .right-obligation-text-para {
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: var(--greyColor);
}
.fee-paymentplan-section .right-obligation-button {
  width: 300px;
  display: flex;
  justify-content: end;
}
.right-obligation-text-button {
  background-color: var(--button-lightblack-lightblue);
  color: var(--TextColor);
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  width: auto;
}
.fee-paymentplan-content-table {
  width: 393px;
  border: 1px solid rgb(228, 224, 224);
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
@media only screen and (max-width: 1320px) {
  .fee-paymentplan-section {
    overflow-x: scroll;
  }
}
.change-fee-popup {
  height: 598px;
  /* border: 3px solid var(--button2); */
  background-color: var(--popup-background-white-black);
}
.generate-payment-popup {
  height: 548px;
  border: 3px solid var(--button2);
  background-color: var(--popup-background-white-black);
}
.inputs-field-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 20px;
}
.inputs-fields-container {
  display: flex;
  gap: 32px;
}
.inputs-fields-container-center {
  display: flex;
  align-items: self-end;
  gap: 32px;
}
.inputs-field-content {
  display: flex;
  /* width: 336px; */
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}
.second-inputs-field-content > div {
  margin-top: 0px !important;
}
.dropdown-inputs-field-content > div {
  margin-top: 0px !important;
  width: 300px !important;
}
.inputs-text-container {
  display: flex;
  justify-content: space-between;
}
.radio-container {
  display: flex;
  padding: 12px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}
.radio-container .radio-container-box {
  display: flex;
  align-items: center;
  gap: 8px;
}
.input-red-label {
  color: #dc2626;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
}
.change-fee-check-icon {
  display: flex;
  align-items: center;
  gap: 2px;
}
.generate-payment-text-container {
  display: flex;
  align-items: flex-start;
  gap: 32px;
}
.generate-payment-text-content {
  display: flex;
  align-items: flex-start;
  gap: 28px;
}
.generate-check-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.payment-alert {
  margin-top: 20px;
}
.goto-obligation-sub-bottom-tab {
  display: flex;
  gap: 15px;
  align-items: center;
}
.goto-obligation-subtabs {
  padding: 15px;
  height: auto;
  border: 1px solid rgb(228, 224, 224);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.three-dot {
  position: absolute;
  right: 4px;
  top: 6px;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 4px;
  color: var(--TextColor);
  cursor: pointer;
}
.fee-schedule-select {
  height: 598px;
  border: 3px solid var(--button2);
  background-color: var(--popup-background-white-black);
}
.fee-schedule-box {
  padding: 20px;
}
.fee-shedule-container {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}
.fee-shedule-container .card-flex {
  display: flex;
  align-items: center;
  gap: 10px;
}
.fee-shedule-container .card-flex-column {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.fee-paymentplan-sectionB .go-to-inputs-field {
  flex: none;
}
.fee-paymentplan-sectionB .inputfield-flex {
  display: flex;
  align-items: flex-end;
  gap: 20px;
}
.fee-paymentplan-sectionB .edit-content-space > div {
  width: 300px !important;
}
