.revenuesummarybutton {
    width: auto;
    background-color: var(--button2);
    color: white;
    cursor: pointer;
}

.revenuesummarybuttons {
    display: flex;
    gap: 20px;
    align-items: center;
    margin: 0 auto;
}