.add-Recipients-popup {
  height: 598px;
  border: 3px solid var(--button2);
  background-color: var(--popup-background-white-black);
}
.add-Recipients-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}
.card-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid rgb(228, 224, 224);
  /* width: 604px; */
  margin: 0 auto;
}
.card-container-header {
  background: var(--btn-grey-black);
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  padding: 10px;
  color: var(--TextColor);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
}
.card-container-header-content {
  padding: 0 10px 10px 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.card-content-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.card-container .dropdown-inputs-field-content > div {
  margin-top: 0px !important;
  width: 275px !important;
}
.card-container .dropdown-inputs-full-field-content > div {
  margin-top: 0px !important;
  width: 576px !important;
}
.card-container .input-flex {
  display: flex;
  align-items: center;
  gap: 15px;
}
.card-container .input-flex-dropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-container .zip-info-input {
  width: 80px;
  height: 40px;
  padding: 8px;
  border: 1px solid var(--greyColor);
  border-radius: 4px;
  margin-top: 20px;
}

.add-Recipients-container .fee-payment-content-info-input {
  width: 271px;
}
.recipients-details {
  width: 520px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 6px;
  border: 1px solid rgb(228, 224, 224);
  border-radius: 6px;
  padding: 11px;
}
.recipients-table-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
