.admin_crumbs {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  margin-top: 25px;
}

.admin_crumbs p {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: var(--crumbs-light-color);
}

.admin_crumbs .active_crumbs {
  color: var(--white-black);
}
