.case-plan-section {
    margin-top: 15px;
    height: auto;
    width: 100%;
  }
  .case-plan-section .case-plan-container {
    display: flex;
    align-items: self-start;
    justify-content: space-between;
  }
  .case-plan-section .case-plan-left,
  .case-plan-right {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 50%;
  }
  .case-plan-section .main-para {
    color: var(--TextColor);
    font-size: 12px;
    font-weight: 540;
    line-height: 16px;
    letter-spacing: 0.4px;
  }
  .case-plan-section .flex-container {
    display: flex;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
  }
  .case-plan-section .flex-div {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .case-plan-section .label {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: var(--greyColor);
  }
  .case-plan-left .inputs-field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0;
  }
  .case-plan-section .border-bottom {
    width: 98%;
    height: 1px;
    background-color: #e4e0e0;
    margin: 50px 0;
  }
  .criminogenic-section {
    display: flex;
    flex-direction: column;
    align-items: self-start;
    gap: 20px;
  }
  .accordion-wrapper {
    width: 100%;
  }
  .accordion-wrapper .wrapper-name-container {
    display: flex;
    align-items: center;
    gap: 15px;
    border-radius: 5px;
    width: fit-content;
    height: 40px;
    background: var(--button-lightblack-lightblue);
    padding: 10px;
    cursor: pointer;
  }
  .accordion-wrapper .wrapper-name-container .wrapper-name {
    font-size: 14px;
    font-family: Readex Pro;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: var(--TextColor);
  }
  .accordion-wrapper .accordion-open-container {
    margin: 10px 0;
  }
  .container-heading {
    background: var(--light-grey-border-textarea);
    color: #333;
    font-size: 14px;
    font-family: Readex Pro;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.25px;
    width: 100%;
    padding: 10px 15px;
    margin-bottom: 15px;
  }
  .multiple-columns {
    background: var(--light-grey-border-textarea);
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    width: 100%;
    padding: 10px 15px;
    margin-bottom: 15px;
  }
  .multiple-columns p {
    /* flex: 1; */
    color: #333;
    font-size: 14px;
    font-family: Readex Pro;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.25px;
  }
  .action_steps{
    width: 70% ;
  }
  .date{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
    padding-left: 15px;
    gap: 46px;

  }
  .container-inputs-fields {
    width: 95%;
    display: flex;
    align-items: flex-start;
    gap: 10px;
    padding: 10px;
    align-items: center;
  }
  .container-inputs-fields .input-field {
    width: 966px;
    height: 40px;
    border: 1px solid #0000001a;
  }
  