.Notes {
  border: 1px solid rgb(190, 189, 189);
  width: 100%;
  height: 810px;
}
.Notes_buttons {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 20px 10px;
}
.Notes_buttons button {
  width: auto;
  height: 40px;
  display: flex;
  justify-content: space-around;
  cursor: pointer;
  background: var(--button-lightblack-lightblue);
}
.Notes_buttons button p {
  color: var(--white-black) !important;
}
.Notes_buttons button:hover {
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  background-color: var(--button2);
  color: #fff;
}
.Notes_buttons button:hover svg path {
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  fill: white;
}
.Notes_buttons button:hover p {
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  color: white;
}
.Notes_buttons_checkbox {
  display: flex;
  align-items: center;
  gap: 5px;
}

.Notes_buttons_checkbox label {
  color: var(--TextColor);
}
.Notes_serch_block {
  display: flex;
  padding: 20px 10px;
  align-items: center;
  gap: 11px;
}
.Notes_serch_block p {
  color: var(--TextColor);
}
.Notes_serch_block input {
  width: 100%;
  height: 35px;
  border: 1px solid rgb(204, 202, 202);
  border-radius: 4px;
}
.Note_Table {
  margin-left: 1%;
  width: 98%;
  margin-top: 1%;
}
.editTable_notes_bottom {
  display: flex;
  justify-content: space-between;
  width: 98%;
}
.editTable_notes_bottom button {
  width: 156px;
  height: 40px;
  margin-top: 26px;
  margin-left: 20px;
  display: flex;
  justify-content: space-around;
  background: var(--button-lightblack-lightblue);
}

.editTable_notes_testarea textarea {
  width: 100%;
  height: 250px;
}
.editTable_notes {
  width: 98%;
  margin-left: 1%;
  margin-top: 2.7%;
}
.editTable_notes_bottom_dropdown {
  width: 50%;
}

.editTable_notes_bottom_dropdown_input {
  display: flex;
  justify-content: space-around;
  width: 30%;
  margin-top: 26px;
  margin-left: 20px;
  align-items: center;
}
.editTable_notes_bottom_dropdown_input p {
  /* margin-top: 10px; */
}
.editTable_notes_bottom_done_button {
  float: right;
}
.editTable_notes_bottom_done_button button {
  width: 96px;
  height: 40px;
  background: var(--button2);
  border-radius: 4px;
  margin-top: 17%;
  margin-bottom: 20px;
  color: white;
}

.notes_edit_popup {
  border: 3px solid var(--button2);
  height: 500px;
}
