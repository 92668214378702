.community_service_tabs {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}
.community_service_tab {
  font-size: 14px;
  font-family: Readex Pro;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  height: 40px;
  padding: 6px 16px;
  cursor: pointer;
  color: var(--icons);
}
.community_service_tab_active {
  border-bottom: 4px solid var(--button2);
  color: var(--icons2);
}
.add_community_service_popup {
  border: 3px solid var(--button2);
  background-color: var(--popup-background-white-black);
  height: 519px;
}
.add_community_service_log_popup {
  border: 3px solid var(--button2);
  background-color: var(--popup-background-white-black);
  height: 618px;
}
.buttons {
  display: flex;
  gap: 20px;
}
.flex-end-buttons {
  justify-content: flex-end;
  width: 100%;
  position: absolute;
  right: 40px;
}
.buttons .btn {
  display: flex;
  align-items: center;
  width: auto;
  height: 40px;
  cursor: pointer;
  font-size: 14px;
  font-family: Readex Pro;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  background: var(--button-lightblack-lightblue);
  color: var(--TextColor);
}
.buttons .btn:disabled {
  background-color: lightgray;
  color: grey;
}
.add_community_service_content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px;
  padding-top: 30px;
}
.add_community_service_content .dx-texteditor-input {
  width: 300px;
  height: 40px;
}
.add_community_service_content .space_removed {
  justify-content: initial;
}
