.widget-container span {
  color: black;
}
.dx-htmleditor-add-image-popup
  > .dx-overlay-content
  > .dx-popup-content
  .dx-tab
  span {
  color: black;
}

.colortogeltheam .dx-icon {
  color: var(--input-color-black-white);
}
